import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Wrapper } from "../../components";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  AppBar,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";
import { openUploadWidget } from "../utils/CloudinaryService";
import { slug, chunk } from "../utils/UtilityFunctions";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Loading from "../../components/Loading/Loading";
import { addProduct } from "../../services/ProductService";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWeightFactors } from "../../services/DeliveryChargeService";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center",
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
  },
  fullWidth: {
    width: "100%",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  root: {
    paddingBottom: theme.spacing(1),
  },
  paginationMenu: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 10px",
    listStyleType: "none",
  },
  menuItem: {
    margin: "0 15px",
    listStyleType: "none",
    display: "none",
  },
  card: {
    position: "relative",
    clear: "both",
  },
  appBar: {
    boxShadow: theme.shadows[0],
  },
  button: {
    display: "block",
    marginTop: theme.spacing(1) * 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddProduct = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pending } = useSelector((state) => state.product);
  const [product, setProduct] = useState({
    productName: "",
    slug: "",
    description: "",
    regularPrice: "",
    salePrice: "",
    categoryID: "",
    subCategoryID: "",
    quantity: "",
    trending: "",
    recommended: "",
    productType: "",
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [attributes, setAttributes] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const { weightFactors } = useSelector((state) => state.deliveryCharge);

  useEffect(() => {
    getWeightFactors(dispatch);
  }, []);

  const ImageList = (props) => {
    let images = props.images;

    const deleteImage = (image) => {
      let allPictures = [].concat.apply([], images);
      let updatedArray = allPictures.filter((img) => img !== image);
      setProduct({ ...product, images: updatedArray });
    };

    return (
      <>
        {images?.map((value, mainIndex) => {
          return (
            <Grid container spacing={1} key={mainIndex}>
              {value?.map((value, index) => {
                return (
                  <Grid item xs={12} sm={12} md={3} lg={3} key={index}>
                    <div >
                      <img src={value} style={{ transform: 'scale(1.3)', margin: '5px auto' }} />
                      <button
                        style={{
                          color: "red",
                          margin: "3px auto",
                          borderRadius: "none",
                          display: 'flex',
                          padding: '5px',
                          cursor: "pointer",
                        }}
                        onClick={() => deleteImage(value)}
                      >
                        <DeleteIcon style={{ width: '18px', height: '18px' }} />
                      </button>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </>
    );
  };

  const beginUpload = (tag) => {
    const uploadOptions = {
      cloudName: "www-ebaaba-com",
      tags: [tag],
      uploadPreset: "products",
    };

    openUploadWidget(uploadOptions, (error, photos) => {
      if (!error) {
        if (photos.event === "queues-end" && product.images) {
          let files = photos.info.files;
          let data = [];
          for (let i = 0; i < files.length; i++) {
            data[i] = photos.info.files[i].uploadInfo.secure_url;
          }
          for (let i = 0; i < data.length; i++) {
            product.images.push(data[i]);
          }
          let updatedImages = product.images;
          setProduct({ ...product, images: updatedImages, imageAdded: true });
        } else if (photos.event === "queues-end") {
          let files = photos.info.files;
          let data = [];
          for (let i = 0; i < files.length; i++) {
            data[i] = photos.info.files[i].uploadInfo.secure_url;
          }
          setProduct({ ...product, images: data });
        }
      } else {
        console.log(error);
      }
    });
  };

  const handleChange = (event) => {
    setProduct({ ...product, [event.target.name]: event.target.value });
    if (event.target.id === "productName") {
      let slugName = slug(event.target.value);
      setProduct({
        ...product,
        productName: event.target.value,
        slug: slugName,
      });
    }

    if (event.target.name === "quantity") {
      setProduct({ ...product, quantity: +event.target.value });
    }

    if (event.target.name === "categoryID") {
      getSubCategories(event.target.value);
    }
  };

  const handleDescription = (value) => {
    setProduct({ ...product, description: value });
  };

  const addRow = (e) => {
    e.preventDefault();
    attributes.push({});
    setAttributes([...attributes]);
  };

  const removeRow = (e, index) => {
    e.preventDefault();
    attributes.splice(index);
    setAttributes([...attributes]);
  };

  const saveAttribute = (e, index) => {
    e.preventDefault();
    const { id, value } = e.target;
    const list = [...attributes];
    list[index][id] = value;
    setAttributes(list);
  };


  const containsOnlyNumbers = (number) => {
    return !isNaN(Number(number));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (product.id === undefined) {
      if (Object.keys(attributes[0]?.length >= 2)) {
        Object.assign(product, { attributes: attributes });
      }
      if(!containsOnlyNumbers(product.salePrice) || !containsOnlyNumbers(product.regularPrice)) {
        toast.error("Price should only be numbers");
        return;
      }

      try {
        addProduct(product, dispatch, history);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getCategories = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;
    let response = await fetch(url + "categories/product-categories", {
      credentials: "include",
    });
    let data = await response.json();

    if (data.success) {
      setCategories([...data.data]);
    }
  };

  const getSubCategories = async (id) => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;
    let response = await fetch(url + "categories/sub-categories?id=" + id, {
      credentials: "include",
    });
    let data = await response.json();

    if (data.success) {
      setSubCategories([...data.data]);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  if (pending) {
    return <Loading />;
  }

  return (
    <Wrapper className={classes.root}>
      <Card className={classes.card}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography color="inherit" variant="h6" className="flexSpacer">
              Add Product
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent className={classes.content}>
          <form>
            <Grid container spacing={1} style={{ alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  type="text"
                  onChange={handleChange}
                  id="productName"
                  name="productName"
                  label="Product Name"
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  required
                  autoComplete="Product Name"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  type="text"
                  onChange={handleChange}
                  id="regularPrice"
                  name="regularPrice"
                  pattern="[0-9]+"
                  label="Regular Price (D)"
                  fullWidth
                  margin="normal"
                  autoComplete="Regular Price (D)"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  type="text"
                  onChange={handleChange}
                  id="salePrice"
                  name="salePrice"
                  pattern="[0-9]+"
                  label="Sale Price (D)"
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  autoComplete="Sale Price (D)"
                  required
                />
              </Grid>
              {attributes?.map((value, index) => {
                return (
                  <Grid container spacing={1} key={index}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        type="text"
                        label="Attribute Name eg Color / Size"
                        className={classes.textField}
                        onChange={(e) => saveAttribute(e, index)}
                        id="attributeName"
                        name="attributeName"
                        pattern="[a-zA-Z]+"
                        fullWidth
                        margin="normal"
                        autoComplete="address"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        type="text"
                        label="Attribute Value eg blue, green"
                        onChange={(e) => saveAttribute(e, index)}
                        id="attributeValue"
                        name="attributeValue"
                        pattern="^[a-zA-Z0-9,:]*$"
                        className={classes.textField}
                        fullWidth
                        margin="normal"
                        autoComplete="address"
                      />
                    </Grid>

                    <Button
                      variant="fab"
                      color="primary"
                      aria-label="Add"
                      onClick={addRow}
                      className={classes.button}
                    >
                      <AddIcon />
                    </Button>
                    <Button
                      variant="fab"
                      color="primary"
                      aria-label="Add"
                      onClick={
                        attributes.length < 2
                          ? null
                          : (e) => {
                            removeRow(e, index === 0 ? 0 : index);
                          }
                      }
                      className={classes.button}
                    >
                      <DeleteIcon />
                    </Button>
                  </Grid>
                );
              })}

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ReactQuill
                  style={{
                    borderTop: "1px solid #949494",
                    height: "200px",
                    margin: "40px 0",
                  }}
                  className={classes.textField}
                  value={product.description}
                  onChange={handleDescription}
                  placeholder={"Product Description"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <hr />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="category">Select Category</InputLabel>
                  <Select
                    labelId="category"
                    id="categoryID"
                    name="categoryID"
                    onChange={handleChange}
                    value={product.categoryID}
                    label="category"
                  >
                    <MenuItem value={0}>Category</MenuItem>
                    {categories?.map((value, index) => {
                      return (
                        <MenuItem value={value.id} key={index}>
                          {value?.category_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="subCategoryID">
                    Select Sub Category
                  </InputLabel>
                  <Select
                    labelId="subCategoryID"
                    value={product.subCategoryID}
                    id="subCategoryID"
                    name="subCategoryID"
                    onChange={handleChange}
                    label="sub category"
                  >
                    <MenuItem value={0}>Sub Category</MenuItem>
                    {subCategories?.map((value, index) => {
                      return (
                        <MenuItem key={index} value={value.id}>
                          {value.sub_category_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                  type="text"
                  id="quantity"
                  name="quantity"
                  onChange={handleChange}
                  label="Qty"
                  style={{ marginBottom: "16px" }}
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  autoComplete="Quantity"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Grid container spacing={1}>
                  <ImageList
                    images={product.images ? chunk(4, product.images) : [[]]}
                  />
                </Grid>
                <Button
                  variant="contained"
                  onClick={() => beginUpload()}
                  color="default"
                  style={{ marginTop: "20px" }}
                  className={classes.button}
                >
                  Upload Images
                  <CloudUploadIcon className={classes.rightIcon} />
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="trending">Choose Trending</InputLabel>
                  <Select
                    labelId="trending"
                    id="trending"
                    name="trending"
                    value={product.trending}
                    onChange={handleChange}
                    label="trending"
                  >
                    <MenuItem value="">Choose..</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="recommended">
                    Choose Recommendation
                  </InputLabel>
                  <Select
                    labelId="recommended"
                    id="recommended"
                    name="recommended"
                    value={product.recommended}
                    onChange={handleChange}
                    label="recommended"
                  >
                    <MenuItem value="">Choose..</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="productType">Choose Product Type</InputLabel>
                  <Select
                    labelId="productType"
                    id="productType"
                    name="productType"
                    value={product.productType}
                    onChange={handleChange}
                    label="productType"
                  >
                    <MenuItem value="">Choose..</MenuItem>
                    {weightFactors?.map((weightFactor) => (
                      <MenuItem
                        key={weightFactor?.key}
                        value={weightFactor?.key}
                      >
                        {weightFactor?.key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              style={{
                width: "200px",
                margin: "20px auto 0 auto",
                display: "flex",
              }}
              onClick={handleSubmit}
            >
              Add Product
            </Button>
          </form>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default AddProduct;
