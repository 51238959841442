import http from "../http-common";
import { toast } from "react-toastify";
// import Swal from "sweetalert2";

  import { handleVendorRequest, createVendorSuccess, 
    getVendorSuccess
    , getTotalVendorSuccess, getTotalApprovedVendorSuccess, getTotalPendingVendorSuccess, getTotalDeactivatedVendorSuccess, handleVendorError, createDeleteSuccess
  } from "../redux/Slices/VendorSlice";
import Swal from "sweetalert2";

export const getAllVendors = async (dispatch, pageNumber) => {
  dispatch(handleVendorRequest());
  const data = await http
  .get(`registered-vendors?page=${pageNumber}`)
  .then((response) => {
    dispatch(createVendorSuccess());
    return response;
  }).catch((error) => {
    dispatch(handleVendorRequest());
    return error;
  })

  return data;
}

export const getAllRegisteredVendors = async (dispatch) => {
  dispatch(handleVendorRequest());
  const data = await http
  .get(`all-registered-vendors`)
  .then((response) => {
    dispatch(createVendorSuccess());
    return response;
  }).catch((error) => {
    dispatch(handleVendorRequest());
    return error;
  })

  return data;
}

export const getTotalVendors = (dispatch) => {
    dispatch(handleVendorRequest());
    http.get('total-vendors')
    .then((response) => {
      dispatch(getTotalVendorSuccess(response.data.data));
    }).catch((error) =>{
      dispatch(handleVendorError());
    })
}

export const getTotalApprovedVendors = (dispatch) => {
  dispatch(handleVendorRequest());
  http.get('total-approved-vendors')
  .then((response) => {
    dispatch(getTotalApprovedVendorSuccess(response.data.data));
  }).catch((error) =>{
    dispatch(handleVendorError());
  })
}

export const getTotalPendingVendors = (dispatch) => {
  dispatch(handleVendorRequest());
  http.get('total-pending-vendors')
  .then((response) => {
    dispatch(getTotalPendingVendorSuccess(response.data.data));
  }).catch((error) =>{
    dispatch(handleVendorError());
  })
}

export const getTotalDeactivatedVendors = (dispatch) => {
  dispatch(handleVendorRequest());
  http.get('total-deactivated-vendors')
  .then((response) => {
    dispatch(getTotalDeactivatedVendorSuccess(response.data.data));
  }).catch((error) =>{
    dispatch(handleVendorError());
  })
}


export const getSingleVendor = async (dispatch, vendorID) => {
  dispatch(handleVendorRequest());
  const data = await http.get(`vendor-information/${vendorID}`)
  .then((response) => {
    dispatch(createVendorSuccess());
    return response.data.data;
  }).catch((error) => {
    dispatch(handleVendorError());
    return error;
  });

  return data;
}


export const updateVendor = (vendorStatus, vendorID, dispatch) => {
  dispatch(handleVendorRequest());
  http
    .put(`update-profile-vendor-status/${vendorID}`, { activated: vendorStatus, id: vendorID })
    .then((response) => {
      if(response.data.success){
        dispatch(createVendorSuccess());
        toast.success(response.data.message);
      }else{
        toast.error('Error updating vendor status');
        dispatch(handleVendorError());
      }
    })
    .catch((error) => {
      toast.error("Vendor update error");
      dispatch(handleVendorError());
    });
};

export const deleteVendor = async (vendorID, dispatch) => {
  Swal.fire({
    title: "Are you sure?",
    text: "This action is not revertable",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      http.get(`delete-vendor/${vendorID}`)
      .then((response) => {
        if(response.data.success){
          dispatch(createDeleteSuccess());
          console.log('Logging response === ', response);
          toast.success(response.data.message);
        }else{
          toast.error(response.data.message);
        }
      }).catch((error) => {
      })
    } else {
    }
  });
};

export const deactivateVendor = async (vendorID, dispatch, history) => {
  Swal.fire({
    title: "Are you sure?",
    text: "This action is not revertable",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, deactivate vendor!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      http.put(`deactivate-vendor/${vendorID}`)
      .then((response) => {
        if(response.data.success){
          dispatch(createDeleteSuccess());
          toast.success(response.data.message);
          history.push('/vendors');
        }else{
          toast.error(response.data.message);
        }
      }).catch((error) => {
      })
    } else {
    }
  });
};




