import { useSelector } from "react-redux";
import { Wrapper } from "../../components";
import AdminDashboard from "../../components/Dashboards/AdminDashboard";
import VendorDashboard from "../../components/Dashboards/VendorDashboard";

const Home = (props) => {
  const { auth } = useSelector((state) => state.auth);

  return <Wrapper>{auth?.type === "admin" || auth?.type === "Admin" || auth?.type === "SuperAdmin"? <AdminDashboard /> : <VendorDashboard />}</Wrapper>;
};

export default Home;
