import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  notification: {
    position: 'absolute',
    top: 0,
    width: '100%',
    // zIndex: 9999,
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled"  {...props} />;
};

const Notification = ({ open, message, handleClose }) => {
  const classes = useStyles();
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} className={classes.notification}
>
      <Alert
        onClose={handleClose}
        severity="info"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
