import {
  CartCard,
  ImageStatCard,
  MapCard,
  NewsCard,
  PostCard,
  ProfileCard,
  StatCard,
  WeatherCard,
  Wrapper,
} from "../../components";
import React, { useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import EmailIcon from "@material-ui/icons/Email";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PhoneIcon from "@material-ui/icons/Phone";
import { mockFeed } from "../../utils/mock";
import red from "@material-ui/core/colors/red";
import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import "./style.css";
import { Link } from "react-router-dom";
import {
  getAllVendors,
  getTotalVendors,
  getTotalApprovedVendors,
  getTotalPendingVendors,
  getTotalDeactivatedVendors,
  deleteVendor,
} from "../../services/VendorServices";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles((theme) => ({
  paginationMenu: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 10px",
    listStyleType: "none",
  },
  menuItem: {
    margin: "0 15px",
    listStyleType: "none",
    display: "none",
  },
}));

const Vendors = (props) => {
  const classes = useStyles();

  const statusPending = {
    padding: "5px 20px",
    backgroundColor: "#D2042D",
    borderRadius: "5px",
    color: "#fff",
  };

  const statusApproved = {
    padding: "5px 20px",
    backgroundColor: "#0047AB",
    borderRadius: "5px",
    color: "#fff",
  };

  const statusDeactivated = {
    padding: "5px 20px",
    backgroundColor: "#9C27B0",
    borderRadius: "5px",
    color: "#fff",
  };

  const orderAnalytics = {
    marginBottom: "15px",
  };
  const dispatch = useDispatch();
  const [vendors, setVendors] = useState([]);
  const [page, setPage] = useState({ page: 1 });
  const [pagination, setPagination] = useState({ last_page: 0 });

  const {
    pending,
    totalVendors,
    totalApprovedVendors,
    totalPendingVendors,
    totalDeactivatedVendors,
    deleteSuccess,
  } = useSelector((state) => state.vendor);

  useEffect(() => {
    fetchAllVendors();
    getTotalVendors(dispatch);
    getTotalApprovedVendors(dispatch);
    getTotalPendingVendors(dispatch);
    getTotalDeactivatedVendors(dispatch);
  }, [page, deleteSuccess]);

  const fetchAllVendors = async () => {
    try {
      const response = await getAllVendors(dispatch, page.page);
      let vendors = [];
      response.data.data.data?.map((item) => {
        vendors.push(item);
      });
      setVendors([...vendors]);
      setPagination({ last_page: response.data.data?.last_page });
    } catch (error) {}
  };

  const next = () => {
    let currentPage = Number(page.page) + 1;
    setPage({ page: currentPage });
    window.scrollTo(0, 0);
    props.history.push("/vendors?page=" + currentPage);
  };

  const prev = () => {
    let currentPage = Number(page.page) - 1;
    if (currentPage === 0) {
      setPage({ page: 1 });
      window.scrollTo(0, 0);
      props.history.push("/vendors?page=" + currentPage);
    } else {
      setPage({ page: currentPage });
      window.scrollTo(0, 0);
      props.history.push("/vendors?page=" + currentPage);
    }
  };

  
  const renderPagination = () => {
    const currentPage = Number(page.page);
    const lastPage = Number(pagination.last_page);
    const pagesToShow = 1; // Number of pages to show before the ellipsis

    const paginationItems = [];

    // First page
    if (currentPage !== 1) {
      paginationItems.push(
        <Button
          key={1}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(1)}
          style={{ marginRight: "10px" }}
        >
          1
        </Button>
      );
    }

    // If current page is not too close to the beginning, add ellipsis
    if (currentPage > pagesToShow + 1) {
      paginationItems.push(<span key="ellipsis1">...</span>);
    }

    // Pages before current page
    for (let i = Math.max(2, currentPage - pagesToShow); i < currentPage; i++) {
      paginationItems.push(
        <Button
          key={i}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(i)}
          style={{ margin: "auto 10px" }}
        >
          {i}
        </Button>
      );
    }

    // Current page
    paginationItems.push(
      <Button
        key={currentPage}
        variant="contained"
        className="pageLink"
        disabled
        style={{ marginRight: "10px" }}
      >
        {currentPage}
      </Button>
    );

    // Pages after current page
    for (
      let i = currentPage + 1;
      i <= Math.min(currentPage + pagesToShow, lastPage - 1);
      i++
    ) {
      paginationItems.push(
        <Button
          key={i}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(i)}
          style={{ marginRight: "10px" }}
        >
          {i}
        </Button>
      );
    }

    // If current page is not too close to the end, add ellipsis
    if (lastPage - currentPage > pagesToShow) {
      paginationItems.push(<span key="ellipsis2">...</span>);
    }

    // Last page
    if (currentPage !== lastPage) {
      paginationItems.push(
        <Button
          key={lastPage}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(lastPage)}
          style={{ margin: "auto 10px" }}
        >
          {lastPage}
        </Button>
      );
    }

    return paginationItems;
  };


  const handlePageChange = (pageNumber) => {
    setPage({ page: pageNumber });
    window.scrollTo(0, 0);
    props.history.push(`/vendors?page=${pageNumber}`);
  };

  const handleDelete = (index) => {
    try {
      deleteVendor(index, dispatch);
    } catch (error) {}
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="All Vendors"
            value={totalVendors !== null && totalVendors ? totalVendors : 0}
            icon={<LocalOfferIcon />}
            color="#3f51b5"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Pending"
            value={
              totalPendingVendors !== null && totalPendingVendors
                ? totalPendingVendors
                : 0
            }
            icon={<NotificationsIcon />}
            color="#f44336"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Approved"
            value={
              totalApprovedVendors !== null && totalApprovedVendors
                ? totalApprovedVendors
                : 0
            }
            icon={<EmailIcon />}
            color="#ffd740"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Deactivated"
            value={
              totalDeactivatedVendors !== null && totalDeactivatedVendors
                ? totalDeactivatedVendors
                : 0
            }
            icon={<PhoneIcon />}
            color="#9c27b0"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Paper
            style={{ width: "100%", marginTop: "20px", overflowX: "auto" }}
          >
            <Table style={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor ID</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell numeric>Mobile</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendors.length >= 1
                  ? vendors.map((value, index) => {
                      return (
                        <TableRow key={index}>
                          {value.account_status === null && (
                            <>
                              <TableCell component="th" scope="row">
                                <Link
                                  to={`/edit-vendor/${value.id}`}
                                  style={{ color: "#3F51B5" }}
                                >
                                  <VisibilityIcon />
                                </Link>
                              </TableCell>
                              <TableCell>{value.created_at}</TableCell>
                              <TableCell>{value.name}</TableCell>
                              <TableCell>{value.phone_number}</TableCell>
                              <TableCell>{value.email}</TableCell>
                              <TableCell>{value.address}</TableCell>

                              <TableCell>
                                <span
                                  style={
                                    value.activated === 0
                                      ? statusPending
                                      : value.activated === 1
                                      ? statusApproved
                                      : value.activated === 2
                                      ? statusDeactivated
                                      : null
                                  }
                                >
                                  {value.activated === 0
                                    ? "Pending"
                                    : value.activated === 1
                                    ? "Approved"
                                    : value.activated === 2
                                    ? "Deactivated"
                                    : null}
                                </span>
                              </TableCell>
                              <TableCell>
                                <Link
                                  onClick={() => handleDelete(value?.id)}
                                  style={{ color: "#D2042D" }}
                                >
                                  <DeleteIcon />
                                </Link>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })
                  : "No vendors"}
              </TableBody>
            </Table>
            {pagination.last_page >= 2 ? (
              <>
                <nav>
                  <ul className="paginationMenu">
                    <li className={"menuItem"}>
                      <Button
                        variant="outlined"
                        disabled={Number(page.page) === 1}
                        className="pageLink"
                        onClick={prev}
                        style={{ marginRight: "10px" }}
                      >
                        Previous
                      </Button>
                    </li>
                    {renderPagination()}
                    <li className="menuItem">
                      <Button
                        variant="outlined"
                        className="pageLink"
                        disabled={
                          Number(page.page) === Number(pagination.last_page)
                        }
                        onClick={next}
                      >
                        Next
                      </Button>
                    </li>
                  </ul>
                </nav>
              </>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Vendors;
