import { createSlice } from "@reduxjs/toolkit";

export const locationSlice = createSlice({
  name: "locations",
  initialState: {
    locations: [],
    pending: false,
    created: false,
    deleted: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleLocationRequest: (state) => {
      state.pending = true;
    },
    createLocationSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.created = true;
      state.deleted = true;
      state.errorMsg = "";
    },
    getLocationSuccess: (state, action) => {
      state.pending = false;
      state.created = false;
      state.deleted = false;
      state.locations = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleLocationError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.created = false;
      state.deleted = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleLocationRequest,
  createLocationSuccess,
  getLocationSuccess,
  handleLocationError,
} = locationSlice.actions;
export default locationSlice.reducer;
