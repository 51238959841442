import { Bar, Bubble, Line } from "react-chartjs-2";
import {
  NewsCard,
  PostCard,
  StatCard,
  WeatherCard,
  Wrapper,
} from "../../components";
import React, { useEffect, useState } from "react";
import { mockDashboard, mockFeed } from "../../utils/mock";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import EmailIcon from "@material-ui/icons/Email";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreIcon from "@material-ui/icons/More";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import Paper from "@material-ui/core/Paper";
import PhoneIcon from "@material-ui/icons/Phone";
import SettingsIcon from "@material-ui/icons/Settings";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import red from "@material-ui/core/colors/red";
import { useDispatch, useSelector } from "react-redux";
import {
  getVendorNewOrders,
  getTotalVendorOrders,
  getTotalVendorProfit,
  getTotalVendorSales,
  getVendorTotalWeeklyProfit,
  getVendorTotalWeeklySales,
  getVendorWeeklyOrders,
  getTotalDailyProfit,
  getVendorWeeklySales,
} from "../../services/DashboardServices";
import Loading from "../../components/Loading/Loading";
import { formatPrice } from "../../helpers";
import {
  data,
  dataBubble,
  dataMixed,
  gridOptions,
  height,
  options,
  sharedOptions,
  stackedGridOptions,
} from "../../utils/mock/chart";
import Typography from "@material-ui/core/Typography";
import { Button, Checkbox, List, ListItem, TextField } from "@material-ui/core";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import { agreeTermsAndConditions, getTermsAndConditions, getTermsAndConditionsDetails } from "../../services/UserManagementService";
import { withStyles } from "@material-ui/core/styles";
const BlueCheckbox = withStyles({
  root: {
    color: "#1976D2",
    "&$checked": {
      color: "#1976D2",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

let id = 0;
function createData(name, date, progress) {
  id += 1;
  return { id, name, date, progress };
}

const VendorDashboard = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [loading, setLoading] = useState(false);

  const chartMenu = (
    <Menu
      id="chart-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <MoreIcon />
        </ListItemIcon>
        <ListItemText primary="View more" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <NotificationsOffIcon />
        </ListItemIcon>
        <ListItemText primary="Disable notifications" />
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Remove panel" />
      </MenuItem>
    </Menu>
  );

  const { termsAndConditionsSuccess } = useSelector((state) => state.user);

  const {
    pending,
    totalSales,
    totalOrders,
    totalProfit,
    totalWeeklySales,
    newOrders,
    totalWeeklyProfit,
    weeklyOrders,
    totalDailyProfit,
    weeklySales,
  } = useSelector((state) => state.dashboard);

  useEffect(() => {
    getTotalVendorSales(dispatch);
    getTotalVendorOrders(dispatch);
    getTotalVendorProfit(dispatch);
    getVendorTotalWeeklySales(dispatch);
    getVendorNewOrders(dispatch);
    getVendorTotalWeeklyProfit(dispatch);
    getVendorWeeklyOrders(dispatch);
    getVendorWeeklySales(dispatch);
  }, []);

  const getTermsAndConstions = async () => {
    try {
      const response = await getTermsAndConditionsDetails(auth?.id);
      setTermsAndConditions(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getTermsAndConstions();
  }, [termsAndConditionsSuccess]);

  const colors = [
    {
      backgroundColor: "#7986cb",
      borderColor: "#3f51b5",
      pointBackgroundColor: "#3f51b5",
      pointBorderColor: "#fff",
    },
    {
      backgroundColor: "#eeeeee",
      borderColor: "#e0e0e0",
      pointBackgroundColor: "#e0e0e0",
      pointBorderColor: "#fff",
    },
    {
      backgroundColor: "rgba(148,159,177,0.2)",
      borderColor: "rgba(148,159,177,1)",
      pointBackgroundColor: "rgba(148,159,177,1)",
      pointBorderColor: "#fff",
    },
  ];

  const labels = [];
  const data = [];

  weeklySales.map((value) => {
    let day = "";
    switch (value.day) {
      case 1:
        day = "Sunday";
        break;
      case 2:
        day = "Monday";
        break;
      case 3:
        day = "Tuesday";
        break;
      case 4:
        day = "Wedneday";
        break;
      case 5:
        day = "Thursday";
        break;
      case 6:
        day = "Friday";
        break;
      case 7:
        day = "Saturday";
        break;
      default:
        break;
    }
    labels.push(day);
    data.push(value.daily_sale);
  });

  const weeklyData = {
    labels,
    datasets: [
      {
        label: "total sales",
        ...colors[1],
        borderWidth: 1,
        data: data,
      },
    ],
  };

  const weeklySalesData = [
    {
      type: "Line",
      title: "Weekly Sales",
      subtitle: "-46,68",
      data: weeklyData,
      height: 200,
      options: {
        ...sharedOptions,
        ...gridOptions,
      },
    },
  ];

  const labels1 = [];
  const data1 = [];

  weeklyOrders.map((value) => {
    let day = "";
    switch (value.day) {
      case 1:
        day = "Sunday";
        break;
      case 2:
        day = "Monday";
        break;
      case 3:
        day = "Tuesday";
        break;
      case 4:
        day = "Wedneday";
        break;
      case 5:
        day = "Thursday";
        break;
      case 6:
        day = "Friday";
        break;
      case 7:
        day = "Saturday";
        break;
      default:
        break;
    }
    labels1.push(day);
    data1.push(value.daily_orders);
  });

  const weeklyData1 = {
    labels: labels1,
    datasets: [
      {
        label: "total orders",
        ...colors[0],
        borderWidth: 1,
        data: data1,
      },
    ],
  };

  const weeklyOrdersData = [
    {
      type: "Bar",
      title: "Weekly Orders",
      subtitle: "-46,68",
      data: weeklyData1,
      height: 200,
      options: {
        ...sharedOptions,
        ...gridOptions,
      },
    },
  ];

  if (pending) {
    return <Loading />;
  }

  return (
    <Grid container spacing={1}>
      {auth?.activated === 0 && (
        <Grid item xs={12} sm={12} md={12}>
          <StatCard
            type="fill"
            subtitle={`Your account is not activated yet`}
            color="#f44336"
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6} md={4}>
        <StatCard
          type="fill"
          title={`Total Sales`}
          subtitle={`+ ${
            totalWeeklySales && new Intl.NumberFormat().format(totalWeeklySales)
          } This Week.`}
          value={`${totalSales && formatPrice(totalSales)}`}
          icon={<LocalOfferIcon />}
          color="#3f51b5"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard
          type="fill"
          title="Orders"
          subtitle={`${newOrders && newOrders.toLocaleString()} New Orders.`}
          value={`${totalOrders && totalOrders.toLocaleString()}`}
          icon={<PhoneIcon />}
          color="#9c27b0"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StatCard
          type="fill"
          title="Profit"
          subtitle={`+ ${
            totalWeeklyProfit && totalWeeklyProfit.toLocaleString()
          } This Week.`}
          value={`${totalProfit && formatPrice(totalProfit)}`}
          icon={<EmailIcon />}
          color="#f44336"
        />
      </Grid>

      {weeklySales.length >= 1 &&
        weeklySalesData?.map((chart, index) => (
          <Grid item xs={12} sm={12} md={6} key={index}>
            <Card>
              <CardHeader
                subheader={chart.title}
                action={
                  <IconButton id={`${index}-menu-button`} onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                }
              />
              <CardContent>
                <Line
                  data={chart.data}
                  height={chart.height}
                  options={chart.options}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}

      {weeklyOrders.length >= 1 &&
        weeklyOrdersData?.map((chart, index) => (
          <Grid item xs={12} sm={12} md={6} key={index}>
            <Card>
              <CardHeader
                subheader={chart.title}
                action={
                  <IconButton id={`${index}-menu-button`} onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                }
              />
              <CardContent>
                <Bar
                  data={chart.data}
                  height={chart.height}
                  options={chart.options}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}

      {/* {!termsAndConditions && (
        <Grid item xs={12} sm={12} md={12}>
          <TermsAndConditions />
        </Grid>
      )} */}
    </Grid>
  );
};


export default VendorDashboard;
