import http from "../http-common";
import { toast } from "react-toastify";
import { handleDeliveryChargeRequest, createDeliveryChargeSuccess, getDeliveryChargeSuccess,
   getWeightFactorSuccess, handleDeliveryChargeError } from "redux/Slices/DeliveryChargeSlice";


export const getDeliveryCharges = (dispatch) => {
  dispatch(handleDeliveryChargeRequest());

  http
    .get(`delivery-charges`)
    .then((response) => {
      dispatch(getDeliveryChargeSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDeliveryChargeError());
      toast.error(error);
    });
};



export const getDeliveryCharge = async (deliveryCharge_id, dispatch) => {
  dispatch(handleDeliveryChargeRequest());
  const data = await http
    .get(`delivery-charge?id=${deliveryCharge_id}`)
    .then((response) => {
      dispatch(createDeliveryChargeSuccess());
      return response.data.data;
    })
    .catch((err) => {
      dispatch(handleDeliveryChargeError());
      return err;
    });

  return data;
};


export const updateDeliveryCharge = (value, deliveryCharge_id, dispatch) => {
  dispatch(handleDeliveryChargeRequest());
  http
    .patch(`delivery-charge/update`, {value: value, id: deliveryCharge_id })
    .then((response) => {
      dispatch(createDeliveryChargeSuccess());
      toast.success(response.data.message);
    })
    .catch((error) => {
      toast.error("Delivery Charge update error");
      dispatch(handleDeliveryChargeError());
      console.error(error);
    });
};

export const getWeightFactors = (dispatch) => {
  dispatch(handleDeliveryChargeRequest());

  http
    .get(`weight-factors`)
    .then((response) => {
      dispatch(getWeightFactorSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDeliveryChargeError());
      toast.error(error);
    });
};



