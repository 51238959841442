import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import { getCategory, updateCategory, addSubCategory } from "../../services/CategoryService";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

export default function EditCategory({ show, close, categoryId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState([{ categoryName: "" }]);
  const { pending } = useSelector((state) => state.category);

  const getCategoryById = async () => {
    try {
      const response = await getCategory(categoryId, dispatch);
      setCategory(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (categoryId !== null) {
      getCategoryById();
    }
  }, [categoryId]);

  const saveSubCategory = (e, index) => {
    e.preventDefault();
    const { id, value } = e.target;
    const temp = [...subCategory];
    temp[index][id] = value;
    setSubCategory(temp);
  };

  const addRow = (e) => {
    e.preventDefault();
    subCategory.push({
      categoryName: "",
    });
    setSubCategory([...subCategory]);
  };

  const removeRow = (e, index) => {
    e.preventDefault();
    if (subCategory.length === 1) return 0;
    subCategory.splice(index, 1);
    setSubCategory([...subCategory]);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      updateCategory(category, categoryId, dispatch, history);
      show();
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setCategory(e.target.value);
  };

  const handleAddSubCategory = async (e) => {
    e.preventDefault();

    try{
      addSubCategory(subCategory, categoryId, dispatch);
      show();
    }catch(error){
      console.error(error);
    }
  } 


  if (pending) {
    return <Loading />;
  }

  return (
    <div>
      <Dialog open={close} onClose={() => show()}>
        <DialogTitle>Edit Category</DialogTitle>
        <>
          <form style={{ margin: "10px 20px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Category"
                  type="text"
                  name="category-name"
                  id="category"
                  value={category !== null && category}
                  onChange={handleChange}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Button
                onClick={handleUpdate}
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            </Grid>
          </form>

          <form style={{ margin: "10px 20px" }}>
            {subCategory?.map((value, index) => {
              return (
                <Grid container spacing={1} key={index}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Sub Category"
                      type="text"
                      name="categoryName"
                      id="categoryName"
                      value={value.categoryName}
                      onChange={(event) => saveSubCategory(event, index)}
                      fullWidth
                      variant="standard"
                    />
                  </Grid>

                  <Button
                    variant="fab"
                    color="primary"
                    aria-label="Add"
                    onClick={addRow}
                    // className={classes.button}
                  >
                    <AddIcon />
                  </Button>
                  <Button
                    variant="fab"
                    color="primary"
                    aria-label="Add"
                    onClick={removeRow}
                    // className={classes.button}
                  >
                    <DeleteIcon />
                  </Button>
                </Grid>
              );
            })}
            <Button onClick={handleAddSubCategory} variant="contained" color="primary">
              Save
            </Button>
          </form>
        </>
        <DialogActions>
          <Button onClick={() => show()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
