import { ProductCard, ManageOwnershipCard, Wrapper } from "../../components";
import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { drawerWidth } from "../../styleVariables";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../../components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  getAlleBaabaProducts,
  getAllVendorProducts,
  getAllCategoryProducts,
  searchProduct,
  searchVendorProduct,
} from "../../services/ProductService";
import { Button, Card, CardContent, TextField } from "@material-ui/core";
import classNames from "classnames";
import SearchIcon from "@material-ui/icons/Search";
import { serialize } from "../utils/UtilityFunctions";
import { Autocomplete } from "@material-ui/lab";
import { getCategories } from "../../services/CategoryService";
import axios from "axios";
import UpdateOwnership from "./UpdateOwnership";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 100,
  },
  toolBar: {
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    maxWidth: drawerWidth,
    [theme.breakpoints.up("md")]: {
      backgroundColor: "transparent",
      overflow: "visible",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
    [theme.breakpoints.up("sm")]: {
      top: "0",
      height: "calc(100vh - 64px)",
    },
    border: 0,
    height: "auto",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    minWidth: 0,
  },
  modal: {
    [theme.breakpoints.down("sm")]: {
      top: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
    zIndex: "1000",
  },
  backdrop: {
    [theme.breakpoints.down("sm")]: {
      top: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
  },
  checkbox: {
    width: "24px",
    height: "24px",
  },
  paginationMenu: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 10px",
    listStyleType: "none",
  },
  menuItem: {
    margin: "0 15px",
    listStyleType: "none",
    display: "none",
  },
  searchWrapper: {
    boxSizing: " border-box",
    position: "relative",
    display: "block",
    maxWidth: "400px",
  },
  searchForm: {
    background: "white",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1) * 2,
    display: "block",
    maxWidth: "400px",
  },
  textField: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  searchInput: {
    fontSize: "1rem",
    padding: theme.spacing(1) * 1.9,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1) * 1.2,
      fontSize: "0.8rem",
    },
    cursor: "text",
    textIndent: "30px",
    border: "none",
    background: "transparent",
    width: "100%",
    outline: "0",
  },
  searchIcon: {
    position: "absolute",
    // top: "50%",
    left: "0",
    // marginTop: "-24px",
    color: "rgba(0,0,0,.87)",
  },
  searchCategory: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonOwnership: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0',
  },
}));

const ManageOwnership = (props) => {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const handleDrawerToggle = () => setOpened(!opened);
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState({ searchParam: "" });
  const [page, setPage] = useState({ page: 1 });
  const [pagination, setPagination] = useState({ last_page: 0 });
  const [options, setOptions] = useState([{ label: "", value: "", id: "" }]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { pending, updateStatus } = useSelector((state) => state.product);
  const { loading: categoryLoading, categories } = useSelector(
    (state) => state.category
  );
  const { auth } = useSelector((state) => state.auth);

  const handleOptionSelected = (event, newValue) => {
    setSelectedOption(newValue);
  };

  useEffect(() => {
    getCategories(dispatch);
  }, []);

  const fetchAllProducts = async () => {
    try {
      let response = await getAlleBaabaProducts(dispatch, page.page);

      let products = [];
      response.data.data?.map((item) => {
        products.push(item);
      });
      setProducts([...products]);
      setPagination({ last_page: response.data?.last_page || 0 });
    } catch (error) { }
  };

  const fetchCategoryProducts = async () => {
    try {
      const response = await getAllCategoryProducts(
        dispatch,
        page.page,
        selectedOption?.id
      );

      let products = [];
      response.data.data?.map((item) => {
        products.push(item);
      });
      setProducts([...products]);
      setPagination({ last_page: response.data?.last_page || 0 });
    } catch (error) {
      console.error(error);
    }
  };

  const next = () => {
    let currentPage = Number(page.page) + 1;
    setPage({ page: currentPage });
    window.scrollTo(0, 0);
    props.history.push("/manage-ownership?page=" + currentPage);
  };

  const prev = () => {
    let currentPage = Number(page.page) - 1;
    if (currentPage === 0) {
      setPage({ page: 1 });
      window.scrollTo(0, 0);
      props.history.push("/manage-ownership?page=" + currentPage);
    } else {
      setPage({ page: currentPage });
      window.scrollTo(0, 0);
      props.history.push("/manage-ownership?page=" + currentPage);
    }
  };

  const renderPagination = () => {
    const currentPage = Number(page.page);
    const lastPage = Number(pagination.last_page);
    const pagesToShow = 1; // Number of pages to show before the ellipsis

    const paginationItems = [];

    // First page
    if (currentPage !== 1) {
      paginationItems.push(
        <Button
          key={1}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(1)}
          style={{ marginRight: "10px" }}
        >
          1
        </Button>
      );
    }

    // If current page is not too close to the beginning, add ellipsis
    if (currentPage > pagesToShow + 1) {
      paginationItems.push(<span key="ellipsis1">...</span>);
    }

    // Pages before current page
    for (let i = Math.max(2, currentPage - pagesToShow); i < currentPage; i++) {
      paginationItems.push(
        <Button
          key={i}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(i)}
          style={{ margin: "auto 10px" }}
        >
          {i}
        </Button>
      );
    }

    // Current page
    paginationItems.push(
      <Button
        key={currentPage}
        variant="contained"
        className="pageLink"
        disabled
        style={{ marginRight: "10px" }}
      >
        {currentPage}
      </Button>
    );

    // Pages after current page
    for (
      let i = currentPage + 1;
      i <= Math.min(currentPage + pagesToShow, lastPage - 1);
      i++
    ) {
      paginationItems.push(
        <Button
          key={i}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(i)}
          style={{ marginRight: "10px" }}
        >
          {i}
        </Button>
      );
    }

    // If current page is not too close to the end, add ellipsis
    if (lastPage - currentPage > pagesToShow) {
      paginationItems.push(<span key="ellipsis2">...</span>);
    }

    // Last page
    if (currentPage !== lastPage) {
      paginationItems.push(
        <Button
          key={lastPage}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(lastPage)}
          style={{ margin: "auto 10px" }}
        >
          {lastPage}
        </Button>
      );
    }

    return paginationItems;
  };

  const handlePageChange = (pageNumber) => {
    setPage({ page: pageNumber });
    window.scrollTo(0, 0);
    props.history.push(`/manage-ownership?page=${pageNumber}`);
  };

  const getSearchProducts = async (searchValue, page) => {
    let serializeSearchParam = serialize({ search: searchValue, page: page });
    try {
      let response = "";

      if (auth?.type === "vendor") {
        response = await searchVendorProduct(serializeSearchParam);
      }

      let products = [];
      response.data.data?.map((item) => {
        products.push(item);
      });
      setProducts([...products]);
      setPagination({ last_page: response.data?.last_page || 0 });
    } catch (error) { }
  };

  const getSearchResults = async () => {
    try {
      let url = "";

      if (!process.env.NODE_ENV === "development") {
        url = process.env.REACT_APP_DEVELOPMENT_API_URL;
      } else {
        url = process.env.REACT_APP_PRODUCTION_API_URL;
      }

      let response = await axios.get(url + "search-index", {
        params: {
          query: search?.searchParam,
          limit: 30,
        },
        withCredentials: true,
      });

      let data = response.data;


      if (data) {
        let products = [];
        data?.map((item) => {
          products.push(item);
        });
        setProducts([...products]);
        setPagination({ last_page: 0 });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSearch = (event) => {
    setSearch({ searchParam: event.target.value });
  };

  useEffect(() => {
    // getSearchResults();
    if (search?.searchParam.length > 2) {
      if (auth?.type === "admin") {
        getSearchResults();
      } else {
        getSearchProducts(search.searchParam, page.page);
      }
    } else if (selectedOption !== null) {
      fetchCategoryProducts();
    } else {
      fetchAllProducts();
    }

  }, [page, search?.searchParam, selectedOption, auth?.type]);

  useEffect(() => {
    if (!categoryLoading) {
      setOptions(
        categories.map((category) => ({
          label: category.category_name,
          value: category.category_name,
          id: category.id,
        }))
      );
    }
  }, [categories, categoryLoading]);

  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };

 // Initialize the state as an array to store checked item IDs
 const [checkedItems, setCheckedItems] = useState([]);

 const handleChange = (event, id) => {
   if (event.target.checked) {
     // Add the id to the array if checked
     setCheckedItems((prevState) => [...prevState, id]);
   } else {
     // Remove the id from the array if unchecked
     setCheckedItems((prevState) => prevState.filter((itemId) => itemId !== id));
   }
 };

  // if (pending) {
  //   return <Loading />;
  // }

  if (Object.keys(products).length === 0) {
    return <Loading />
  }


  return (
    <Wrapper className={classes.root}>
      <Card className={classes.card}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Typography color="inherit" variant="h6" className="flexSpacer">
                All eBaaba Products
              </Typography>
            </div>

            <div className={classes.searchCategory}>
              <Autocomplete
                style={{ width: "200px", height: "auto" }}
                options={options}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                value={selectedOption}
                onChange={handleOptionSelected}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Search by Category"
                    variant="outlined"
                    className={classes.textField}
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </div>

            <div className={classes.searchWrapper}>
              <form className={classNames(classes.searchForm, "mr-0")}>
                <IconButton aria-label="Search" className={classes.searchIcon}>
                  <SearchIcon />
                </IconButton>
                <input
                  className={classes.searchInput}
                  type="text"
                  id="searchBox"
                  onChange={handleSearch}
                  placeholder="Search"
                  autoFocus="true"
                />
              </form>
            </div>
          </Toolbar>
        </AppBar>
        <CardContent className={classes.content}>
          <Wrapper padding={false}>
            <Hidden mdUp>
              <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => handleDrawerToggle()}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" color="inherit" noWrap>
                    Products
                  </Typography>
                </Toolbar>
              </AppBar>
            </Hidden>
            <Grid className={classes.root}>

              <main className={classes.content}>
                <Grid container spacing={1}>
                  {products.length >= 1 ? (
                    products?.map((product, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                        <ManageOwnershipCard
                          id={product?.id}
                          name={product?.name}
                          url={product?.url}
                          price={product?.sale_price}
                          description={product?.description}
                          sale={product?.sale_price}
                          regular={product?.regular_price}
                          slug={product?.slug}
                          checked={checkedItems.includes(product.id)} // Check if the id is in the state array
                          click={(e) => handleChange(e, product.id)} // Handle the checkbox click event
                        />
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="h6">No Products</Typography>
                  )}
                </Grid>
                {checkedItems?.length > 0 ? <div className={classes.buttonOwnership}>
                  <Button
                    onClick={() => handleToggle()}
                    variant="contained"
                    color="primary"
                  >
                    Update Ownership
                  </Button>
                </div> : <>
                {pagination.last_page >= 2 ? (
              <>
                <nav>
                  <ul className="paginationMenu">
                    <li className={"menuItem"}>
                      <Button
                        variant="outlined"
                        disabled={Number(page.page) === 1}
                        className="pageLink"
                        onClick={prev}
                        style={{ marginRight: "10px" }}
                      >
                        Previous
                      </Button>
                    </li>
                    {renderPagination()}
                    <li className="menuItem">
                      <Button
                        variant="outlined"
                        className="pageLink"
                        disabled={
                          Number(page.page) === Number(pagination.last_page)
                        }
                        onClick={next}
                      >
                        Next
                      </Button>
                    </li>
                  </ul>
                </nav>
              </>
            ) : null}
                </>}
               


             
              </main>
            </Grid>
          </Wrapper>
        </CardContent>
      </Card>
      {/* Update Ownership Modal */}
      <UpdateOwnership show={handleToggle} close={toggle} checkedItems={checkedItems} />
    </Wrapper>
  );
};

export default ManageOwnership;
