import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Wrapper, Example } from "../../components";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  AppBar,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";
import { openUploadWidget } from "../utils/CloudinaryService";
import { slug, chunk } from "../utils/UtilityFunctions";
import AddIcon from "@material-ui/icons/Add";
// import MinusIcon from '@material-ui/icons/Minus';
import Icon from "@material-ui/core/Icon";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTermsAndConditions } from "../../services/UserManagementService";

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center",
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
  },
  fullWidth: {
    width: "100%",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  root: {
    paddingBottom: theme.spacing(1),
  },
  paginationMenu: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 10px",
    listStyleType: "none",
  },
  menuItem: {
    margin: "0 15px",
    listStyleType: "none",
    display: "none",
  },
  card: {
    position: "relative",
    clear: "both",
  },
  appBar: {
    boxShadow: theme.shadows[0],
  },
  button: {
    display: "block",
    marginTop: theme.spacing(1) * 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddTermsAndConditions = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pending } = useSelector((state) => state.user);
  const [content, setContent] = useState(''); 

  const handleContent = (value) => {
   setContent(value);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      addTermsAndConditions(content, dispatch, history);
    }catch(error) {}
  }

  if(pending) {
    return <Loading />;
  }

  return (
    <Wrapper className={classes.root}>
      <Card className={classes.card}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography color="inherit" variant="h6" className="flexSpacer" style={{display: 'flex'}}>
              <span style={{marginRight:'10px'}}>Add Terms And Conditons</span>
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent className={classes.content}>
          <form style={{width: '100%'}}> 
            <Grid container spacing={1} style={{ alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ReactQuill
                  style={{ borderTop: "1px solid #949494", height: '200px', margin: '40px 0' }}
                  className={classes.textField}
                  value={content}
                  onChange={handleContent}
                  placeholder={"Terms and Conditions"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <hr />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              style={{
                width: "200px",
                margin: "20px auto 0 auto",
                display: "flex",
              }}
              onClick={handleUpdate}
            >
              Add Content
            </Button>
          </form>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default AddTermsAndConditions;
