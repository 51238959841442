import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    pending: false,
    loading: false,
    error: false,
    updateStatus: false,
    errorMsg: "",
  },

  reducers: {
    handleProductRequest: (state) => {
      state.pending = true;
      state.loading = true;
    },
    createProductSuccess: (state) => {
      state.pending = false;
      state.loading = false;
      state.error = false;
      state.updateStatus = true;
      state.errorMsg = "";
    },
    getProductSuccess: (state, action) => {
      state.pending = false;
      state.loading = false;
      state.products = action.payload;
      state.error = false;
      state.updateStatus = false;
      state.errorMsg = "";
    },
    handleProductError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.loading = false;
      state.updateStatus = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
    handleProductRequest,
    createProductSuccess,
    getProductSuccess,
    handleProductError,
} = productSlice.actions;
export default productSlice.reducer;
