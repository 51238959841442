import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { loginUser } from "../../services/AuthService";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/Loading/Loading";
import { toast } from "react-toastify";
import { resetPasswordWithToken } from "../../services/UserManagementService";

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center",
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
  },
  fullWidth: {
    width: "100%",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
  },
}));

const ChangePassword = () => {
  const { pending } = useSelector((state) => state.user);
  const [currentToken, setCurrentToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // Create a URLSearchParams object using the URL
    const urlSearchParams = new URLSearchParams(location.search);
    // Get the value of the 'token' parameter
    const token = urlSearchParams.get("token");
    setCurrentToken(token);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      if(newPassword.length < 1){
        toast.error("Password is empty");
        return;
      } 
      if(confirmNewPassword.length < 1){
        toast.error('Confirm password is empty');
        return;
      }
      if (newPassword !== confirmNewPassword) {
        toast.error("Passwords do not match");
        return;
      }

      resetPasswordWithToken(currentToken, newPassword, dispatch, history);
    } catch (error) {}
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <div className={classNames(classes.session, classes.background)}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <Card>
            <CardContent>
              <form>
                <div
                  className={classNames(classes.logo, `text-xs-center pb-xs`)}
                >
                  <img
                    src={`https://res.cloudinary.com/www-ebaaba-com/image/upload/v1682029596/ebaaba-logo_kzmjyx.png`}
                    style={{
                      width: "300px",
                      height: "140px",
                      verticalAlign: "middle",
                      objectFit: "cover",
                      margin: "auto",
                      filter:
                        "brightness(0) saturate(100%) invert(16%) sepia(100%) saturate(7478%) hue-rotate(207deg) brightness(95%) contrast(103%)",
                    }}
                    alt="eBaaba logo"
                    className="block"
                  />
                  <Typography variant="caption">
                    Change your password
                  </Typography>
                </div>

                <TextField
                  id="newPassword"
                  name="newPassword"
                  label="New Password"
                  className={classes.textField}
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  autoComplete="New Password"
                  fullWidth
                  margin="normal"
                  required
                />

                <TextField
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  label="Confirm New Password"
                  className={classes.textField}
                  type="password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  autoComplete="Confirm New Password"
                  fullWidth
                  margin="normal"
                  required
                />

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  onClick={handleSubmit}
                >
                  Change Password
                </Button>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
