import http from "../http-common";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  handleOrderRequest,
  createOrderSuccess,
  getOrderSuccess,
  getTotalOrderSuccess,
  getTodayOrderSuccess,
  getPendingOrderSuccess,
  getCompletedOrderSuccess,
  handleOrderError,
} from "../redux/Slices/OrderSlice";

export const getAllOrders = async (dispatch, pageNumber) => {
  dispatch(handleOrderRequest());
  const data = await http
    .get(`orders?page=${pageNumber}`)
    .then((response) => {
      dispatch(createOrderSuccess());
      return response;
    })
    .catch((error) => {
      dispatch(handleOrderError());
      return error;
    });

  return data;
};

export const getAllVendorOrders = async (dispatch, pageNumber) => {
  dispatch(handleOrderRequest());
  const data = await http
    .get(`vendor-orders?page=${pageNumber}`)
    .then((response) => {
      dispatch(createOrderSuccess());
      return response;
    })
    .catch((error) => {
      dispatch(handleOrderError());
      return error;
    });

  return data;
};

export const getOrder = async (dispatch, orderID) => {
  dispatch(handleOrderRequest());
  const data = await http
    .get(`order-registration-status?order_id=${orderID}`)
    .then((response) => {
      dispatch(createOrderSuccess());
      return response;
    })
    .catch((error) => {
      dispatch(handleOrderError());
      return error;
    });

  return data;
};

export const getTotalOrders = (dispatch) => {
  dispatch(handleOrderRequest());
  http
    .get("total-orders")
    .then((response) => {
      dispatch(getTotalOrderSuccess(response.data.Total));
    })
    .catch((error) => {
      dispatch(handleOrderError());
    });
};

export const getTotalVendorOrders = (dispatch) => {
  dispatch(handleOrderRequest());
  http
    .get("vendor-total-orders")
    .then((response) => {
      dispatch(getTotalOrderSuccess(response.data.Total));
    })
    .catch((error) => {
      dispatch(handleOrderError());
    });
};

export const getTodayOrders = (dispatch) => {
  dispatch(handleOrderRequest());
  http
    .get("today-orders")
    .then((response) => {
      dispatch(getTodayOrderSuccess(response.data.Today));
    })
    .catch((error) => {
      dispatch(handleOrderError());
    });
};

export const getVendorTodayOrders = (dispatch) => {
  dispatch(handleOrderRequest());
  http
    .get("vendor-today-orders")
    .then((response) => {
      dispatch(getTodayOrderSuccess(response.data.Today));
    })
    .catch((error) => {
      dispatch(handleOrderError());
    });
};

export const getPendingOrders = (dispatch) => {
  dispatch(handleOrderRequest());
  http
    .get("pending-orders")
    .then((response) => {
      dispatch(getPendingOrderSuccess(response.data.Pending));
    })
    .catch((error) => {
      dispatch(handleOrderError());
    });
};

export const getVendorPendingOrders = (dispatch) => {
  dispatch(handleOrderRequest());
  http
    .get("vendor-pending-orders")
    .then((response) => {
      dispatch(getPendingOrderSuccess(response.data.Pending));
    })
    .catch((error) => {
      dispatch(handleOrderError());
    });
};

export const getCompletedOrders = (dispatch) => {
  dispatch(handleOrderRequest());
  http
    .get("completed-orders")
    .then((response) => {
      dispatch(getCompletedOrderSuccess(response.data.Completed));
    })
    .catch((error) => {
      dispatch(handleOrderError());
    });
};

export const getVendorCompletedOrders = (dispatch) => {
  dispatch(handleOrderRequest());
  http
    .get("vendor-completed-orders")
    .then((response) => {
      dispatch(getCompletedOrderSuccess(response.data.Completed));
    })
    .catch((error) => {
      dispatch(handleOrderError());
    });
};

export const completeOrder = (orderNumber, dispatch, history) => {
  dispatch(handleOrderRequest());
  http
    .put(`complete-order`, { order_number: orderNumber })
    .then((response) => {
      dispatch(createOrderSuccess());
      toast.success("Order completed");
      history.push("/orders/manage-orders");
    })
    .catch((error) => {
      toast.error("Order update error");
      dispatch(handleOrderError());
    });
};

export const cancelOrder = (orderNumber, dispatch, history) => {
  dispatch(handleOrderRequest());
  Swal.fire({
    title: "Are you sure?",
    text: "Your want to cancel the order",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, cancel it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      http
        .put(`cancel-order`, { order_number: orderNumber })
        .then((response) => {
          dispatch(createOrderSuccess());
          toast.success("Order cancelled");
          window.location.reload();
          // history.push("/orders");
        })
        .catch((error) => {
          toast.error("Order update error");
          dispatch(handleOrderError());
        });
    } else {
      dispatch(handleOrderError());
    }
  });
};

export const updateOrderStatus = (
  orderNumber,
  orderStatus,
  dispatch,
  history
) => {
  dispatch(handleOrderRequest());
  Swal.fire({
    title: "Are you sure?",
    text: "Your want to update the order",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: `Yes, ${
      orderStatus === "Processing"
        ? "Confirmed"
        : orderStatus === "Confirmed"
        ? "Set On the Way"
        : orderStatus === "On the Way"
        ? "Delivered"
        : null
    } ${orderStatus !== "Confirmed" ? "it!" : ""}`,
  }).then(async (result) => {
    if (result.isConfirmed) {
      http
        .put(`update-order-status`, {
          order_number: orderNumber,
          order_status: orderStatus,
        })
        .then((response) => {
          dispatch(createOrderSuccess());
          toast.success("Order status updated");
          window.location.reload();
          // history.push(`/edit-order/${order}`);
        })
        .catch((error) => {
          toast.error("Order update error");
          dispatch(handleOrderError());
        });
    } else {
      dispatch(handleOrderError());
    }
  });
};

export const deleteOrder = async (orderID, dispatch, history) => {
  dispatch(handleOrderRequest());

  Swal.fire({
    title: "Are you sure?",
    text: "Your want to delete the order",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        let response = await http.delete("delete-order", {
          data: { orderID: orderID },
        });

        let data = response.data;

        if (data.OrderDeleted) {
          toast.success("Order Deleted!");
          dispatch(createOrderSuccess());
          history.push("/orders/manage-orders");
          return;
        }
        if(!data.success) {
          toast.error(data.message);
          dispatch(handleOrderError());
        } else {
          toast.error("Error! Deleting Order!");
          dispatch(handleOrderError());
        }

      } catch (error) {
        toast.error("Error! Deleting Order!");
        dispatch(handleOrderError());
      }
    } else {
      dispatch(handleOrderError());
    }
  });
};
