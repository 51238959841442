import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "user",
  initialState: {
    update: Date.now(),
    auth: null,
    location: null,
    permission: null,
    session: localStorage.getItem("session"),
    token: localStorage.getItem("token"),
    isAuthenticated: localStorage.getItem("isAuthenticated"),
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    loginRequest: (state) => {
      state.pending = true;
    },
    CancelPending: (state) => {
      state.pending = false;
    },
    registerRequest: (state) => {
      state.pending = true;
    },
    loginSuccess: (state, action) => {
      state.pending = false;
      // state.isAuthenticated = true;
      state.auth = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    registerSuccess: (state, action) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    loginError: (state, action) => {
      state.error = true;
      state.pending = false;
      // state.isAuthenticated = false;
      state.errorMsg = action.payload;
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem("session");
    },
    loginErrorVerify: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload.error;
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("token");
      localStorage.removeItem("session");
      // window.location.href = "/";
      action.payload.history.push("/signin");
    },
    registerError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    logout: (state) => {
      state.error = false;
      state.auth = null;
      state.isAuthenticated = false;
      localStorage.clear();
      window.location.href = "/";
    }
  },
});

export const {
  loginRequest,
  registerRequest,
  loginSuccess,
  CancelPending,
  registerSuccess,
  registerError,
  loginError,
  loginErrorVerify,
  updateState,
  logout,
} = authSlice.actions;
export default authSlice.reducer;
