import http from "../http-common";
import { toast } from "react-toastify";
import { handleLocationRequest, createLocationSuccess, getLocationSuccess, handleLocationError } from "redux/Slices/LocationSlice";
import Swal from "sweetalert2";

export const addLocation = (data, dispatch) => {
  dispatch(handleLocationRequest());
  http
  .post("add-location", {location_name: data})
    .then((response) => {
      if(response.data.success){
        dispatch(createLocationSuccess());
        toast.success(response.data.message);
      }else{ 
        dispatch(handleLocationError());
        toast.error(response.data.message);  
      }
    })
    .catch((error) => {
      toast.error('An error occurred');
      dispatch(handleLocationError());
    });
};


export const getLocations = (dispatch) => {
  dispatch(handleLocationRequest());

  http
    .get(`all-locations`)
    .then((response) => {
      dispatch(getLocationSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleLocationError());
      toast.error(error);
    });
};



export const getLocation = async (location_id, dispatch) => {
  dispatch(handleLocationRequest());
  const data = await http
    .get(`location?id=${location_id}`)
    .then((response) => {
      dispatch(createLocationSuccess());
      return response.data.data;
    })
    .catch((err) => {
      dispatch(handleLocationError());
      return err;
    });

  return data;
};


export const updateLocation = (distance, location_id, dispatch) => {
  dispatch(handleLocationRequest());
  http
    .patch(`location/update`, {distance: distance, id: location_id })
    .then((response) => {
      dispatch(createLocationSuccess());
      toast.success(response.data.message);
    })
    .catch((error) => {
      toast.error("Category update error");
      dispatch(handleLocationError());
      console.error(error);
    });
};

export const deleteLocation = async (location_id, dispatch) => {
  dispatch(handleLocationRequest());
  Swal.fire({
    title: "Are you sure?",
    text: "This action is not revertable",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {

      try {
        let response = await http.delete('locations/delete-location', {
          data: { id: location_id },
        });

        let data = response.data;

        if (data.success) {
          toast.success('Location Deleted!');
          dispatch(createLocationSuccess());
          return;
        }
        toast.error('Error! Deleting Location!');
        dispatch(handleLocationError());
      } catch (error) {
        toast.error('Error! Deleting Location!');
        dispatch(handleLocationError());
      }
    }else{
      dispatch(handleLocationError());
    }
  });
};


