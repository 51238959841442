import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import { getLocation, updateLocation } from "../../services/LocationService";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

export default function EditCategoryPrices({ show, close, categoryId }) {
  const dispatch = useDispatch();
  const [location, setLocation] = useState({
    distance: ""
  });
  const { pending } = useSelector((state) => state.location);

  const getLocationById = async () => {
    try {
      const response = await getLocation(categoryId, dispatch);
      setLocation({ distance: response[0]?.distance });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (categoryId !== null) {
      getLocationById();
    }
  }, [categoryId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      updateLocation(location?.distance, categoryId, dispatch);
      show();
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocation((prevLocation) => ({
      ...prevLocation,
      [name]: value,
    }));
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <div>
      <Dialog open={close} onClose={() => show()}>
        <DialogTitle>Edit Category Prices</DialogTitle>
        <form style={{ margin: "10px 20px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                autoFocus
                margin="dense"
                label="Distance in KM"
                type="text"
                name="distance"
                id="distance"
                value={location?.distance}
                onChange={handleChange}
                fullWidth
                variant="standard"
              />
            </Grid>
          </Grid>
          <DialogActions
            style={{
              display: "flex",
              padding: "10px 0",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={handleUpdate} variant="contained" color="primary">
              Save
            </Button>
            <Button onClick={() => show()}>Cancel</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
