import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Slices/Auth';
import userReducer from './Slices/UserManagementSlice';
import productReducer from './Slices/ProductSlice';
import categoryReducer from './Slices/CategorySlice';
import orderReducer from './Slices/OrderSlice';
import vendorReducer from './Slices/VendorSlice';
import dashboardReducer from './Slices/DashboardSlice';
import profileReducer from './Slices/ProfileSlice';
import locationReducer from './Slices/LocationSlice';
import deliveryChargeReducer from './Slices/DeliveryChargeSlice';

export const store = configureStore({
	reducer: {
		// add reducers here
		auth: authReducer,
		user: userReducer,
		product: productReducer,
		category: categoryReducer,
		order: orderReducer,
		vendor: vendorReducer,
		dashboard: dashboardReducer,
		profile: profileReducer,
		location: locationReducer,
		deliveryCharge: deliveryChargeReducer
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
