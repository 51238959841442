import http from "../http-common";
import { toast } from "react-toastify";
// import Swal from "sweetalert2";
import { getProfileSuccess, handleProfileRequest, handleProfileError, createProfileSuccess } from "../redux/Slices/ProfileSlice";


export const getUserProfile = (dispatch) => {
  dispatch(handleProfileRequest());
   http.get('user-profile')
  .then((response) => {
    dispatch(getProfileSuccess(response.data));
  }).catch((error) =>{
    dispatch(handleProfileError());
  })
}


export const updateProfile = (name, phone_number, email, address, password, business_name, type_of_product, dispatch, history) => {
  dispatch(handleProfileRequest());
  http
    .post(`update-profile`, {name: name, phone_number: phone_number, email: email, address: address, password: password, business_name: business_name, type_of_product: type_of_product })
    .then((response) => {
      dispatch(createProfileSuccess());
      toast.success(response.data.message);
      history.push('/profile');
    })
    .catch((error) => {
      toast.error("Profile update error");
      dispatch(handleProfileError());
      console.error(error);
    });
};


