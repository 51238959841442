import http from "../http-common";
import { toast } from "react-toastify";
import { handleCategoryRequest, createCategorySuccess, getCategorySuccess, handleCategoryError } from "../redux/Slices/CategorySlice";
import Swal from "sweetalert2";

export const addCategory = (data, dispatch, history) => {
  dispatch(handleCategoryRequest());
  http
    .post("categories/create-category", {name: data})
    .then((response) => {
      dispatch(createCategorySuccess());
      toast.success(response.data.message);
    })
    .catch((error) => {
      toast.error("Created category error");
      dispatch(handleCategoryError());
    });
};

export const addSubCategory = (subCategory, category_id, dispatch) => {
  dispatch(handleCategoryRequest());
  http
    .post("sub-category/add", {subCategories: subCategory, id: category_id})
    .then((response) => {
      dispatch(createCategorySuccess());
      toast.success(response.data.message);
    })
    .catch((error) => {
      toast.error("Created subcategory error");
      dispatch(handleCategoryError());
    });
};

export const getCategories = (dispatch) => {
  dispatch(handleCategoryRequest());

  http
    .get(`categories/product-categories`)
    .then((response) => {
      dispatch(getCategorySuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleCategoryError());
      toast.error(error);
    });
};

export const getCategory = async (category_id, dispatch) => {
  dispatch(handleCategoryRequest());
  const data = await http
    .get(`category?id=${category_id}`)
    .then((response) => {
      dispatch(createCategorySuccess());
      return response.data.data;
    })
    .catch((err) => {
      dispatch(handleCategoryError());
      return err;
    });

  return data;
};

export const getSubCategory = async (category_id, dispatch) => {
  dispatch(handleCategoryRequest());
  const data = await http
    .get(`sub-category?id=${category_id}`)
    .then((response) => {
      dispatch(createCategorySuccess());
      return response.data.data;
    })
    .catch((err) => {
      dispatch(handleCategoryError());
      return err;
    });

  return data;
};

export const updateCategory = (categoryName, category_id, dispatch, history) => {
  dispatch(handleCategoryRequest());
  http
    .patch(`category/update`, {categoryName: categoryName, id: category_id })
    .then((response) => {
      dispatch(createCategorySuccess());
      toast.success(response.data.message);
    })
    .catch((error) => {
      toast.error("Category update error");
      dispatch(handleCategoryError());
      console.error(error);
    });
};

export const updateSubCategory = (subCategoryName, category_id, dispatch, history) => {
  dispatch(handleCategoryRequest());
  http
    .patch(`sub-category/update`, {name: subCategoryName, id: category_id })
    .then((response) => {
      dispatch(createCategorySuccess());
      toast.success(response.data.message);
    })
    .catch((error) => {
      toast.error("Category update error");
      dispatch(handleCategoryError());
      console.error(error);
    });
};

export const deleteCategory = async (category_id, dispatch) => {
  dispatch(handleCategoryRequest());
  Swal.fire({
    title: "Are you sure?",
    text: "This action is not revertable",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {

      try {
        let response = await http.delete('categories/delete-category', {
          data: { id: category_id },
        });

        let data = response.data;

        if (data.success) {
          toast.success('Category Deleted!');
          dispatch(createCategorySuccess());
          return;
        }
        toast.error('Error! Deleting Category!');
        dispatch(handleCategoryError());
      } catch (error) {
        toast.error('Error! Deleting Category!');
        dispatch(handleCategoryError());
      }
    }else{
      dispatch(handleCategoryError());
    }
  });
};


export const deleteSubCategory = async (category_id, dispatch) => {
  dispatch(handleCategoryRequest());
  Swal.fire({
    title: "Are you sure?",
    text: "This action is not revertable",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {

      try {
        let response = await http.delete('sub-category/delete', {
          data: { id: category_id },
        });

        let data = response.data;

        if (data.success) {
          toast.success('Category Deleted!');
          dispatch(createCategorySuccess());
          return;
        }
        toast.error('Error! Deleting Category!');
        dispatch(handleCategoryError());
      } catch (error) {
        toast.error('Error! Deleting Category!');
        dispatch(handleCategoryError());
      }
    }else{
      dispatch(handleCategoryError());
    }
  });
};

