import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Wrapper, Example } from "../../components";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { AppBar, TextField, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { getUserProfile, updateProfile } from "../../services/ProfileServices";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
  paginationMenu: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 10px",
    listStyleType: "none",
  },
  menuItem: {
    margin: "0 15px",
    listStyleType: "none",
    display: "none",
  },
  card: {
    position: "relative",
    clear: "both",
  },
  appBar: {
    boxShadow: theme.shadows[0],
  },
  button: {
    display: "block",
    marginTop: theme.spacing(1) * 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const EditProfile = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getUserProfile(dispatch);
  }, []);

  const { pending, profiles } = useSelector((state) => state.profile);

  const [name, setName] = useState(profiles.name);
  const [phone_number, setNumber] = useState(profiles.phone_number);
  const [email, setEmail] = useState(profiles.email);
  const [address, setAddress] = useState(profiles.address);
  const [password, setPassword] = useState("");
  const [business_name, setBusinessName] = useState(profiles.business_name);
  const [type_of_product, setTypeOfProduct] = useState(profiles.type_of_product);
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [profile, setProfile] = useState([]);
  const handleUpdate = (e) => {
    e.preventDefault();
    try {
      if(password !== "" && confirmPassword === ""){
        toast.error("Confirm password should not be empty");
        return;
      }

      if(password === "" && confirmPassword !== ""){
        toast.error("password should not be empty");
        return;
      }

      if(password !== confirmPassword){
        toast.error('Passwords do not match');
        return;
      }

      updateProfile(
        name,
        phone_number,
        email,
        address,
        password,
        business_name,
        type_of_product,
        dispatch,
        history
      );
    } catch (error) {}
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <Wrapper className={classes.root}>
      <Card className={classes.card}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography color="inherit" variant="h6" className="flexSpacer">
              Edit Profile
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent className={classes.content}>
          <form>
            <TextField
              type="text"
              id="name"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={classes.textField}
              fullWidth
              margin="normal"
              required
              autoComplete="name"
            />
            <TextField
              type="text"
              id="number"
              label="Phone Number"
              className={classes.textField}
              fullWidth
              margin="normal"
              value={phone_number}
              onChange={(e) => setNumber(e.target.value)}
              autoComplete="phone_number"
              required
            />
            <TextField
              type="email"
              id="email"
              label="Email"
              className={classes.textField}
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              required
            />
            <TextField
              type="text"
              id="address"
              label="Address"
              className={classes.textField}
              fullWidth
              margin="normal"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              autoComplete="address"
              required
            />
              <TextField
              type="text"
              id="business_name"
              label="Business Name"
              className={classes.textField}
              fullWidth
              margin="normal"
              value={business_name}
              onChange={(e) => setBusinessName(e.target.value)}
              autoComplete="business_name"
              required
            />
               <TextField
              type="text"
              id="type_of_product"
              label="Type of Product"
              className={classes.textField}
              fullWidth
              margin="normal"
              value={type_of_product}
              onChange={(e) => setTypeOfProduct(e.target.value)}
              autoComplete="type_of_product"
              required
            />
            <TextField
              id="password"
              label="Change Password"
              className={classes.textField}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
              autoComplete="password"
            />
            <TextField
              id="comfirm-password"
              label="Confirm Password"
              className={classes.textField}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              autoComplete="new-password"
              fullWidth
              margin="normal"
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              onClick={handleUpdate}
              style={{
                width: "200px",
                margin: "20px auto 0 auto",
                display: "flex",
              }}
            >
              Update Profile
            </Button>
          </form>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default EditProfile;
