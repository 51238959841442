import React, { useEffect, useState } from "react";
import {
    AppBar,
    Grid,
    Toolbar,
    Typography,
    TextField,
    Card,
    CardContent,
    Button,
    makeStyles,
} from "@material-ui/core";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading/Loading";
import { addUser } from "../../services/UserManagementService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import CartItems from "../../components/CardItems/CardItems";
import { Wrapper } from "components";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(1),
    },
    card: {
        position: "relative",
        clear: "both",
    },
    appBar: {
        boxShadow: theme.shadows[0],
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    content: {
        padding: theme.spacing(3),
    },
    textField: {
        margin: theme.spacing(1),
    },
    formGrid: {
        flex: 1,
    },
    cartGrid: {
        width: '100%',
    },
}));

const CreateOrder = (props) => {
    const classes = useStyles();

    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        type: "",
    });

    const dispatch = useDispatch();
    const history = useHistory();
    const { pending } = useSelector((state) => state.user);

    const handleChange = (event) => {
        setUser((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const handleRegister = async (event) => {
        event.preventDefault();

        if (user.password !== user.confirmPassword) {
            toast.error('Password do not match');
            return;
        }

        try {
            addUser(user, dispatch, history);
        } catch (error) {
            // Handle error
        }
    };

    const [cart, setCart] = useState([]);
    const [numberOfCartItems, setNumberOfCartItems] = useState(0);
    const [cartTotal, setCartTotal] = useState(0);
    const [checkoutInfo, setCheckoutInfo] = useState({
        name: "",
        townCity: "",
        address: "",
        delivery_address: "",
        phoneNumber: "",
        country: null,
        note: "",
    });
    const [loading, setLoading] = useState(false);
    const [loadingCart, setLoadingCart] = useState(false);
    const [modeOfDelivery, setModeOfDelivery] = useState("");
    const [orderNumer, setOrderNumber] = useState();
    const [deliveryCharge, setDeliveryCharge] = useState(0);

    const saveCheckoutData = async (e) => {
        const { id, value } = e.target;

        if (id === 'delivery_address') {
            setCheckoutInfo({ ...checkoutInfo, delivery_address: value });
        } else {
            setCheckoutInfo({ ...checkoutInfo, [id]: value });
        }
    };

    const handleNote = (value) => {
        setCheckoutInfo({ ...checkoutInfo, note: value });

    };

    const getOrderNumber = async () => {
        let url = "";

        if (process.env.NODE_ENV === "development") {
            url = process.env.REACT_APP_DEVELOPMENT_API_URL;
        } else {
            url = process.env.REACT_APP_PRODUCTION_API_URL;
        }


        const data = await axios
            .get(`${url}order-generator`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error.response;
            });

        setOrderNumber(data);
    };

    let session_id =
        localStorage.getItem("session_id");
    console.log("Logging session id === ", session_id);

    // gets cart items
    const getCartItems = async () => {
        setLoadingCart(true);
        let url = "";

        if (process.env.NODE_ENV === "development") {
            url = process.env.REACT_APP_DEVELOPMENT_API_URL;
        } else {
            url = process.env.REACT_APP_PRODUCTION_API_URL;
        }

        let response = await fetch(
            url + "cart-items",
            {
                credentials: "include",
            }
        );

        let data = await response.json();
        if (data.success) {
            setCart(data.data);
            setNumberOfCartItems(data.data.length);
            let total = 0;
            data.data.map((value) => {
                total += Number(value.qty * value.sale_price);
            });
            await setCartTotal(total);
        } else {
            setCart([]);
        }
        setLoadingCart(false);
    };

    useEffect(() => {
        getCartItems();
        getOrderNumber();
    }, []);


    // Utility function to remove HTML tags
    function removeHtmlTags(str) {
        if (str === null || str === '') return false;
        else str = str.toString();
        return str.replace(/<[^>]*>/g, '');
    }

    const checkout = async (e) => {
        e.preventDefault();
        setLoading(true);

        let orderItems = [];
        cart.map((value) => {
            orderItems = [
                ...orderItems,
                {
                    productID: value.product_id,
                    productName: value.name,
                    qty: value.qty,
                    salePrice: value.sale_price,
                    img: JSON.parse(value.url)[0],
                    creatorID: value.creator_id,
                },
            ];
        });

        let uniqpref = orderItems[0].productName[0] + orderItems[0].productName[1];
        // let orderID = Math.floor(Math.random() * 90000) + 10000;
        // let orderIDString = uniqpref + "-" + orderID;
        let updatedCustomerInfo = {
            ...checkoutInfo,
            townCity: checkoutInfo.address,
        };

        let sanitizedNote = removeHtmlTags(checkoutInfo.note);

        let updatedCustomer = {
            customerInfo: updatedCustomerInfo,
            orderItems: orderItems,
            orderID: orderNumer,
            total: cartTotal,
            mode_of_delivery: modeOfDelivery,
            delivery_fee: deliveryCharge,
            orderNote: sanitizedNote,
            shippingAddress: checkoutInfo.address,
            deliveryAddress: checkoutInfo.delivery_address,
        };

        let session_id =
            localStorage.getItem("session_id") !== null
                ? localStorage.getItem("session_id")
                : undefined;


        let completedInfo = {
            ...updatedCustomer,
            paymentMethod: "Pay On Delivery",
            session_id: session_id,
        };
        let url = "";

        if (process.env.NODE_ENV === "development") {
            url = process.env.REACT_APP_DEVELOPMENT_API_URL;
        } else {
            url = process.env.REACT_APP_PRODUCTION_API_URL;
        }

        let response = await fetch(url + "create-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify(completedInfo),
            credentials: "include",
        });

        let data = await response.json();
        if (data.success) {
            history.push('/orders/manage-orders');
            toast.success('Order create successfully');
        } else {
            toast.error('error creating order');
        }


        setLoading(false);

    }



    if (loading) {
        return <Loading />;
    }

    return (
        <Wrapper className={classes.root}>
            <Card className={classes.card}>
                <AppBar position="static" color="default" className={classes.appBar}>
                    <Toolbar>
                        <Typography color="inherit" variant="h6" className="flexSpacer">
                            Create New Order
                        </Typography>
                    </Toolbar>
                </AppBar>
                <CardContent className={classes.content}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={9} className={classes.formGrid}>
                            <form >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="text"
                                            id="name"
                                            name="name"
                                            label="Full Name"
                                            onChange={saveCheckoutData}
                                            className={classes.textField}
                                            fullWidth
                                            margin="normal"
                                            required
                                            autoComplete="Full Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="text"
                                            id="address"
                                            name="town"
                                            label="Town / City"
                                            onChange={saveCheckoutData}
                                            className={classes.textField}
                                            fullWidth
                                            margin="normal"
                                            required
                                            autoComplete="Town / City"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="text"
                                            id="delivery_address"
                                            name="address"
                                            label="Delivery Address / Google Code"
                                            onChange={saveCheckoutData}
                                            className={classes.textField}
                                            fullWidth
                                            margin="normal"
                                            required
                                            autoComplete="Delivery Address / Google Code"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="text"
                                            id="phoneNumber"
                                            name="phone"
                                            label="Phone Number"
                                            onChange={saveCheckoutData}
                                            className={classes.textField}
                                            fullWidth
                                            margin="normal"
                                            required
                                            autoComplete="Phone Number"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="email"
                                            id="email"
                                            name="email"
                                            label="Email"
                                            className={classes.textField}
                                            fullWidth
                                            margin="normal"
                                            onChange={saveCheckoutData}
                                            autoComplete="Email"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReactQuill
                                            style={{
                                                borderTop: "1px solid #949494",
                                                height: "180px",
                                                margin: "40px 0",
                                            }}
                                            className={classes.textField}
                                            value={checkoutInfo.note}
                                            onChange={handleNote}
                                            placeholder={"Order Note"}
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    onClick={checkout}
                                    style={{
                                        width: "160px",
                                        margin: "20px auto 0 auto",
                                        display: "flex",
                                    }}
                                >
                                    Create Order
                                </Button>
                            </form>
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.cartGrid}>
                            <CartItems items={cart} numberOfCartItems={numberOfCartItems} cartTotal={cartTotal} loadingCart={loadingCart} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Wrapper>
    );
};

export default CreateOrder;
