import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
  } from "@material-ui/core";
  import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
  import { toast } from 'react-toastify';
  import Loading from "../../components/Loading/Loading";
  import { getSubCategory, updateSubCategory } from "../../services/CategoryService";
  
  export default function EditSubCategory({ show, close, subCategoryId }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [subCategory, setSubCategory] = useState("");

    const { pending } = useSelector(state => state.category);

    const getSubCategoryById = async () => {
      try {
        const response  = await getSubCategory(subCategoryId, dispatch);
        setSubCategory(response);
      }catch(error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if(subCategoryId !== null){
        getSubCategoryById();
      }
    }, [subCategoryId]);  

    const handleUpdate = async (e) => {
      e.preventDefault();

      try {
        updateSubCategory(subCategory, subCategoryId, dispatch, history);
        show();
      }catch(error){
        console.error(error);
      }
    }
  
    const handleChange = (e) => {
      setSubCategory(e.target.value);
    };

   if(pending){
    return <Loading />;
   }
  
    return (
      <div>
        <Dialog open={close} onClose={() => show()}>
          <DialogTitle>Edit Sub Category</DialogTitle>
          <DialogContent style={{ width: "280px" }}>
            <TextField
              autoFocus
              margin="dense"
              label="Category Name"
              type="text"
              name="category-name"
              id="subCategory"
              value={subCategory !== null && subCategory}
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => show()}>Cancel</Button>
            <Button onClick={handleUpdate} variant="contained" color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  