import { ProductCard, ProductReviewCard, Wrapper } from "../../components";
import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { drawerWidth } from "../../styleVariables";
import { makeStyles } from "@material-ui/core/styles";
import Loading from "../../components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPendingProducts,
  searchProduct,
  searchVendorProduct,
} from "../../services/ProductService";
import { Card, CardContent } from "@material-ui/core";
import classNames from "classnames";
import SearchIcon from "@material-ui/icons/Search";
import { serialize } from "../utils/UtilityFunctions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 100,
  },
  toolBar: {
    paddingLeft: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1) / 2,
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    maxWidth: drawerWidth,
    [theme.breakpoints.up("md")]: {
      backgroundColor: "transparent",
      overflow: "visible",
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
    [theme.breakpoints.up("sm")]: {
      top: "0",
      height: "calc(100vh - 64px)",
    },
    border: 0,
    height: "auto",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    minWidth: 0,
  },
  modal: {
    [theme.breakpoints.down("sm")]: {
      top: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
    zIndex: "1000",
  },
  backdrop: {
    [theme.breakpoints.down("sm")]: {
      top: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      top: "64px",
    },
  },
  checkbox: {
    width: "24px",
    height: "24px",
  },
  paginationMenu: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 10px",
    listStyleType: "none",
  },
  menuItem: {
    margin: "0 15px",
    listStyleType: "none",
    display: "none",
  },
  searchWrapper: {
    boxSizing: " border-box",
    position: "relative",
    display: "block",
    maxWidth: "400px",
  },
  searchForm: {
    background: "white",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1) * 2,
    display: "block",
    maxWidth: "400px",
  },
  searchInput: {
    fontSize: "1rem",
    padding: theme.spacing(1) * 1.9,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1) * 1.2,
    },
    cursor: "text",
    textIndent: "30px",
    border: "none",
    background: "transparent",
    width: "100%",
    outline: "0",
  },
  searchIcon: {
    position: "absolute",
    top: "50%",
    left: "0",
    marginTop: "-24px",
    color: "rgba(0,0,0,.87)",
  },
}));

const ManageProductsReview = (props) => {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleDrawerToggle = () => setOpened(!opened);
  const dispatch = useDispatch();
  const menu = (
    <>
      <List subheader={<ListSubheader disableSticky>Brands</ListSubheader>}>
        {["Apple", "Microsoft", "Samsung", "HTC", "Sony", "Huawei"].map(
          (value) => (
            <ListItem key={value} role={undefined} button>
              <Checkbox
                tabIndex={-1}
                disableRipple
                className={classes.checkbox}
              />
              <ListItemText primary={value} />
            </ListItem>
          )
        )}
      </List>
      <Divider />
      <List
        subheader={<ListSubheader disableSticky>Screen Size</ListSubheader>}
      >
        {["16 GB", "32 GB", "64 GB", "128 GB"].map((value) => (
          <ListItem
            key={value}
            role={undefined}
            button
            className={classes.listItem}
          >
            <Checkbox
              tabIndex={-1}
              disableRipple
              className={classes.checkbox}
            />
            <ListItemText primary={value} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader disableSticky>Operating System</ListSubheader>
        }
      >
        {["Android", "iOS", "Windows"].map((value) => (
          <ListItem
            key={value}
            role={undefined}
            button
            className={classes.listItem}
          >
            <Checkbox
              tabIndex={-1}
              disableRipple
              className={classes.checkbox}
            />
            <ListItemText primary={value} />
          </ListItem>
        ))}
      </List>
    </>
  );
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState({ searchParam: "" });
  const [page, setPage] = useState({ page: 1 });
  const [pagination, setPagination] = useState({ last_page: 0 });
  const { pending } = useSelector((state) => state.product);

  const { auth } = useSelector((state) => state.auth);

  const fetchAllProducts = async () => {
    try {
      let response = await getAllPendingProducts(dispatch, page.page);;

      let products = [];
      response.data.data?.map((item) => {
        products.push(item);
      });
      setProducts([...products]);
      setPagination({ last_page: response.data.data?.last_page });
    } catch (error) { }
  };


  const next = () => {
    let currentPage = Number(page.page) + 1;
    setPage({ page: currentPage });
    window.scrollTo(0, 0);
    props.history.push("/product-review?page=" + currentPage);
  };

  const prev = () => {
    let currentPage = Number(page.page) - 1;
    if (currentPage === 0) {
      setPage({ page: 1 });
      window.scrollTo(0, 0);
      props.history.push("/product-review?page=" + currentPage);
    } else {
      setPage({ page: currentPage });
      window.scrollTo(0, 0);
      props.history.push("/product-review?page=" + currentPage);
    }
  };

  const renderPagination = () => {
    const currentPage = Number(page.page);
    const lastPage = Number(pagination.last_page);
    const pagesToShow = 1; // Number of pages to show before the ellipsis

    const paginationItems = [];

    // First page
    if (currentPage !== 1) {
      paginationItems.push(
        <Button
          key={1}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(1)}
          style={{ marginRight: "10px" }}
        >
          1
        </Button>
      );
    }

    // If current page is not too close to the beginning, add ellipsis
    if (currentPage > pagesToShow + 1) {
      paginationItems.push(<span key="ellipsis1">...</span>);
    }

    // Pages before current page
    for (let i = Math.max(2, currentPage - pagesToShow); i < currentPage; i++) {
      paginationItems.push(
        <Button
          key={i}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(i)}
          style={{ margin: "auto 10px" }}
        >
          {i}
        </Button>
      );
    }

    // Current page
    paginationItems.push(
      <Button
        key={currentPage}
        variant="contained"
        className="pageLink"
        disabled
        style={{ marginRight: "10px" }}
      >
        {currentPage}
      </Button>
    );

    // Pages after current page
    for (
      let i = currentPage + 1;
      i <= Math.min(currentPage + pagesToShow, lastPage - 1);
      i++
    ) {
      paginationItems.push(
        <Button
          key={i}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(i)}
          style={{ marginRight: "10px" }}
        >
          {i}
        </Button>
      );
    }

    // If current page is not too close to the end, add ellipsis
    if (lastPage - currentPage > pagesToShow) {
      paginationItems.push(<span key="ellipsis2">...</span>);
    }

    // Last page
    if (currentPage !== lastPage) {
      paginationItems.push(
        <Button
          key={lastPage}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(lastPage)}
          style={{ margin: "auto 10px" }}
        >
          {lastPage}
        </Button>
      );
    }

    return paginationItems;
  };


  const handlePageChange = (pageNumber) => {
    setPage({ page: pageNumber });
    window.scrollTo(0, 0);
    props.history.push(`/product-review?page=${pageNumber}`);
  };


  const getSearchProducts = async (searchValue, page) => {
    let serializeSearchParam = serialize({ search: searchValue, page: page });
    try {
      let response = "";
      if (auth?.type === "admin") {
        response = await searchProduct(serializeSearchParam);
      }

      if (auth?.type === "vendor") {
        response = await searchVendorProduct(serializeSearchParam);
      }

      let products = [];
      response.data.data?.map((item) => {
        products.push(item);
      });
      setProducts([...products]);
      //setPagination({ last_page: response.data?.last_page });
    } catch (error) { }
  };

  const handleSearch = (event) => {
    setSearch({ searchParam: event.target.value });
  };

  useEffect(() => {
    if (search.searchParam.length > 2) {
      getSearchProducts(search.searchParam, page.page);
    } else {
      fetchAllProducts();
    }
  }, [page, search]);

  if (pending && Object.keys(products)?.length === 0) {
    return <Loading />;
  }

  return (
    <Wrapper className={classes.root}>
      <Card className={classes.card}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography color="inherit" variant="h6" className="flexSpacer">
              All Pending Products
            </Typography>
            <div className={classes.searchWrapper}>
              <form className={classNames(classes.searchForm, "mr-0")}>
                <IconButton aria-label="Search" className={classes.searchIcon}>
                  <SearchIcon />
                </IconButton>
                <input
                  className={classes.searchInput}
                  type="text"
                  id="searchBox"
                  onChange={handleSearch}
                  placeholder="Search"
                  autoFocus="true"
                />
              </form>
            </div>
          </Toolbar>
        </AppBar>
        <CardContent className={classes.content}>
          <Wrapper padding={false}>
            <Hidden mdUp>
              <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => handleDrawerToggle()}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" color="inherit" noWrap>
                    Products
                  </Typography>
                </Toolbar>
              </AppBar>
            </Hidden>
            <Grid className={classes.root}>
              <main className={classes.content}>
                <Grid container spacing={1}>
                  {products.length >= 1 ? (
                    products?.map((product, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                        <ProductReviewCard
                          id={product.id}
                          name={product.name}
                          url={product.url}
                          price={product.sale_price}
                          description={product.description}
                          sale={product.sale_price}
                          regular={product.regular_price}
                          slug={product.slug}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Typography variant="h6">No Product</Typography>
                  )}
                </Grid>
                {pagination.last_page >= 2 ? (
                  <>
                    <nav>
                      <ul className="paginationMenu">
                        <li className={"menuItem"}>
                          <Button
                            variant="outlined"
                            disabled={Number(page.page) === 1}
                            className="pageLink"
                            onClick={prev}
                            style={{ marginRight: "10px" }}
                          >
                            Previous
                          </Button>
                        </li>
                        {renderPagination()}
                        <li className="menuItem">
                          <Button
                            variant="outlined"
                            className="pageLink"
                            disabled={
                              Number(page.page) === Number(pagination.last_page)
                            }
                            onClick={next}
                          >
                            Next
                          </Button>
                        </li>
                      </ul>
                    </nav>
                  </>
                ) : null}
              </main>
            </Grid>
          </Wrapper>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default ManageProductsReview;
