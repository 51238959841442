import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  agreeTermsAndConditions,
  getTermsAndConditions,
} from "../../services/UserManagementService";
import "./style.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    fontFamily: "Arial, sans-serif",
    fontSize: "25px",
    color: "#333",
    lineHeight: "1.6",
    marginBottom: theme.spacing(2),
  },
}));

const BlueCheckbox = withStyles({
  root: {
    color: "#1976D2",
    "&$checked": {
      color: "#1976D2",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function TermsAndConditions({ terms_and_conditions }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [content, setContent] = useState("");
  const [checked, setChecked] = useState(false);
  const [signature, setSignature] = useState("");
  const { auth } = useSelector((state) => state.auth);
  const { pending } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const fetchTermsAndConditions = async () => {
    try {
      setLoading(true);
      const response = await getTermsAndConditions();
      setContent(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTermsAndConditions();
  }, []);

  const handleChange = (event) => {
    if (signature.length >= 3) {
      setChecked(event.target.checked);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checked) {
      toast.error("Please agree to terms");
      return;
    }

    try {
      if (checked) {
        agreeTermsAndConditions(auth?.id, signature, dispatch);
      }
    } catch (error) {}
  };

  if (loading) {
    return <Loading />;
  }

  if (pending) {
    return <Loading />;
  }

  return (
    <Fragment>
      <div
        dangerouslySetInnerHTML={{
          __html: `${content}`,
        }}
      />

      {/* {content && auth?.type === "vendor" && (
        <form
          style={{
            display: "flex",
            alignItems: "center",
            maxWidth: "350px",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <TextField
            id="signature"
            name="signature"
            label="Enter Your Name"
            type="text"
            value={signature}
            onChange={(e) => setSignature(e.target.value)}
            autoComplete="Enter Your Name"
            halfWidth
            margin="none"
            required
          />
          <label
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "-27px",
              padding: "0 10px",
            }}
          >
            <span
              style={{
                fontFamily: "Roboto, Helvetica, Arial, sans-serif;",
                fontSize: "14px",
                paddingLeft: "10px",
              }}
            >
              Agree:{" "}
            </span>
            <BlueCheckbox
              checked={checked}
              style={{}}
              onChange={handleChange}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </label>
          <Button
            variant="contained"
            color="primary"
            halfWidth
            type="submit"
            style={{ marginBottom: "-18px", fontSize: "12px" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </form>
      )} */}
    </Fragment>
  );
}

export default TermsAndConditions;
