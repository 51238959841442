// Pages
import {
  Home,
  ManageUsers,
  AddUser,
  Vendors,
} from './pages';


// Icons
import ExploreIcon from '@material-ui/icons/Explore';
import PersonIcon from '@material-ui/icons/Person';
import StoreIcon from '@material-ui/icons/Store';
import CategoryIcon from '@material-ui/icons/Category';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LocationOn from '@material-ui/icons/LocationOn';

// Vendors
import EditVendor from './pages/Vendors/EditVendor';

// Manage Categories
import ManageCategories from './pages/Categories/ManageCategories';

// Profile
import Profile from './pages/Profile/Profile';
import EditProfile from './pages/Profile/EditProfile';

// Products
import ManageProducts from './pages/Store/ManageProducts';
import AddProduct from './pages/Store/AddProduct';
import EditProduct from './pages/Store/EditProduct';
import ManageOwnership from './pages/Store/ManageOwnership';

// Product review
import ManageProductsReview from './pages/ProductReview/ManageProductsReview';
import EditProductReview from './pages/ProductReview/EditProductReview';

// Orders
import Orders from './pages/Orders/Orders';
import EditOrder from './pages/Orders/EditOrder';
import CreateOrder from './pages/Orders/CreateOrder';

// Terms and Conditions
import AddTermsAndConditions from './pages/TermsAndConditions/AddTermsAndConditions';
import ManageTermsAndConditions from './pages/TermsAndConditions/ManageTermsAndConditions';

// Manage Locations
import ManageLocations from './pages/Locations/ManageLocations';

// Manage Deliveries
import ManageDeliveries from './pages/Deliveries/ManageDeliveries';

export const routes =
  [
    {
      path: '/',
      name: 'Dashboard',
      type: 'link',
      userType: ['SuperAdmin','Admin','admin', 'vendor'],
      icon: ExploreIcon,
      component: Home
    },
    {
      path: '/profile',
      name: 'Mange Profile',
      type: 'link',
      userType: ['SuperAdmin','Admin','admin', 'vendor'],
      icon: AccountCircleIcon,
      component: Profile
    },
    {
      path: '/edit-profile',
      name: 'Edit Profile',
      type: 'hiddenMenu',
      userType: ['SuperAdmin','Admin','admin', 'vendor'],
      icon: ExploreIcon,
      component: EditProfile
    },
    {
      path: '/store',
      name: 'Manage Store',
      type: 'submenu',
      userType: ['SuperAdmin','Admin','admin', 'vendor'],
      icon: StoreIcon,
      children: [
        {
          path: '/our-store',
          name: 'Manage Products',
          component: ManageProducts
        },
        {
          path: '/add-product',
          name: 'Add Product',
          component: AddProduct
        }
      ]
    },
    {
      path: '/manage-ownership',
      name: 'Manage Ownership',
      type: 'link',
      userType: ['SuperAdmin','Admin','admin'],
      icon: StoreIcon,
      component: ManageOwnership
    },
    {
      path: '/product-review',
      name: 'Product Review',
      type: 'link',
      userType: ['SuperAdmin','Admin','admin'],
      icon: StoreIcon,
      component: ManageProductsReview
    },
    {
      path: '/edit-product/:slug',
      name: 'Edit Product',
      type: 'hiddenMenu',
      userType: ['SuperAdmin','Admin','admin', 'vendor'],
      icon: ExploreIcon,
      component: EditProduct
    },
    {
      path: '/edit-product-review/:slug',
      name: 'Edit Product',
      type: 'hiddenMenu',
      userType: ['admin','SuperAdmin','Admin'],
      icon: ExploreIcon,
      component: EditProductReview
    },
    {
      path: '/orders',
      name: 'Manage Orders',
      type: 'submenu',
      userType: ['SuperAdmin','Admin','admin', 'vendor'],
      icon: ShoppingCartIcon,
      children: [
        {
          path: '/manage-orders',
          name: 'All Orders',
          component: Orders
        },
        {
          path: '/create-order',
          name: 'Create New Order',
          component: CreateOrder
        }
      ]
    },
    {
      path: '/edit-order/:orderID',
      name: 'Edit Order',
      type: 'hiddenMenu',
      userType: ['SuperAdmin','Admin','admin', 'vendor'],
      icon: ExploreIcon,
      component: EditOrder
    },
    {
      path: '/user',
      name: 'UserManagement',
      type: 'submenu',
      userType: ['SuperAdmin','Admin','admin'],
      icon: PersonAddIcon,
     
      children: [
        {
          path: '/manage-users',
          name: 'Manage Users',
          component: ManageUsers
        },
        {
          path: '/add-user',
          name: 'Add User',
          component: AddUser
        }
      ]
    },
    {
      path: '/categories',
      name: 'Manage Categories',
      type: 'link',
      userType: ['SuperAdmin','Admin','admin'],
      icon: CategoryIcon,
      component: ManageCategories
    },
    {
      path: '/vendors',
      name: 'Manage Vendors',
      type: 'link',
      userType: ['SuperAdmin','Admin','admin'],
      icon: PersonIcon,
      component: Vendors
    },
    {
      path: '/edit-vendor/:vendorID',
      name: 'Edit Vendor',
      type: 'hiddenMenu',
      userType: ['SuperAdmin','Admin','admin'],
      icon: ExploreIcon,
      component: EditVendor
    },
    {
      path: '/add-terms',
      name: 'Add Terms',
      type:'hiddenMenu',
      userType: ['SuperAdmin','Admin','admin'],
      icon: ExploreIcon,
      component: AddTermsAndConditions
    },
    {
      path: '/manage-terms-and-conditions',
      name: 'Manage Terms',
      type: 'link',
      userType: ['SuperAdmin','Admin','admin'],
      icon: PersonIcon,
      component: ManageTermsAndConditions
    },
    {
      path: '/locations',
      name: 'Manage Locations',
      type: 'link',
      userType: ['SuperAdmin','Admin','admin'],
      icon: LocationOn,
      component: ManageLocations
    },
    {
      path: '/deliveries',
      name: 'Manage Deliveries',
      type: 'link',
      userType: ['SuperAdmin','Admin','admin'],
      icon: LocationOn,
      component: ManageDeliveries
    } 
];

export default routes;

