import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { loginUser } from "../../services/AuthService";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/Loading/Loading";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  card: {
    overflow: "visible"
  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  background: {
    backgroundColor: theme.palette.primary.main
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center"
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto"
  },
  fullWidth: {
    width: "100%"
  },
  logo: {
    display: "flex",
    flexDirection: "column"
  }
}));

const Signin = () => {
  const { pending, auth } = useSelector((state) => state.auth);
  const classes = useStyles();
  const [credentials, setCredentials] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setCredentials({...credentials, [e.target.id]: e.target.value});
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      // if(auth.type === 'customer'){
      //   toast.error("login fails");
      //   return;
      // }
      loginUser(credentials, dispatch, history);;
      
      setLoading(false);
    }catch(error){
      setLoading(false);
    }
    
  }

  if(loading) {
    return <Loading />;
  }

  if(pending){
    return <Loading />;
  }
  
  return (
    <div className={classNames(classes.session, classes.background)}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <Card>
            <CardContent>
              <form>
                <div
                  className={classNames(classes.logo, `text-xs-center pb-xs`)}
                >
                  <img
                    src={`https://res.cloudinary.com/www-ebaaba-com/image/upload/v1682029596/ebaaba-logo_kzmjyx.png`}
                    style={{width: '300px', height: '140px', verticalAlign: 'middle', objectFit: 'cover', margin: 'auto', 
                    filter: 'brightness(0) saturate(100%) invert(16%) sepia(100%) saturate(7478%) hue-rotate(207deg) brightness(95%) contrast(103%)'
                  }}
                    alt="eBaaba logo"
                    className="block"
                  />
                  <Typography variant="caption">
                    Sign in with your app id to continue.
                  </Typography>
                </div>
                <TextField
                  type="email"
                  id="email"
                  label="Email"
                  className={classes.textField}
                  onChange={handleChange}
                  autoComplete="email"
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="password"
                  label="Password"
                  className={classes.textField}
                  type="password"
                  onChange={handleChange}
                  autoComplete="password"
                  fullWidth
                  margin="normal"
                />
                <FormControlLabel
                  control={<Checkbox value="checkedA" />}
                  label="Stayed logged in"
                  className={classes.fullWidth}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  onClick={handleSubmit}
                >
                  Login
                </Button>
                <div className="pt-1 text-md-center">
                  <Link to="/forgot">
                    <Button>Forgot password?</Button>
                  </Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/signup">
                    <Button>Create new account.</Button>
                  </Link>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Signin;
