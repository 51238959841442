import { createSlice } from "@reduxjs/toolkit";

export const userManagementSlice = createSlice({
  name: "User Management",
  initialState: {
    users: [],
    userProfile: {},
    pending: false,
    tokenSuccess: false,
    termsAndConditionsSuccess: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleUserManagementRequest: (state) => {
      state.pending = true;
      state.termsAndConditionsSuccess = true;
      state.tokenSuccess  = false;
    },
    createUserManagementSuccess: (state) => {
      state.pending = false;
      state.termsAndConditionsSuccess = false;
      state.tokenSuccess = true;
      state.error = false;
      state.errorMsg = "";
    },
    getUserManagementSuccess: (state, action) => {
      state.pending = false;
      state.users = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getUserProfileSuccess: (state, action) => {
        state.pending = false;
        state.userProfile = action.payload;
        state.error = false;
        state.errorMsg = "";
    },
    handleUserManagementError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.termsAndConditionsSuccess = false;
      state.tokenSuccess = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleUserManagementRequest,
  createUserManagementSuccess,
  getUserManagementSuccess,
  getUserProfileSuccess,
  handleUserManagementError,
} = userManagementSlice.actions;
export default userManagementSlice.reducer;
