import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { registerVendor, checkAuth } from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getPdfFile } from "../../services/UserManagementService";


const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center",
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
  },
  fullWidth: {
    width: "100%",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
  },
}));

const buttonStyle = {
  backgroundColor: "#3646A7", // Set the background color
  color: "#ffffff", // Set the text color
  "&:hover": {
    backgroundColor: "#3646A7", // Set the background color on hover
  },
  "&:disabled": {
    backgroundColor: "#3646A7", // Set the background color when disabled
    color: "#fff", // Set the text color when disabled
  },
};

const Signup = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [vendor, setVendor] = useState({
    name: "",
    email: "",
    address: "",
    type_of_product: "",
    business_name: "",
    phone_number: "",
    password: "",
    confirmpassword: "",
  });

  const handleChange = (e) => {
    setVendor({ ...vendor, [e.target.name]: e.target.value });
  };

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (vendor.password !== vendor.confirmpassword) {
        toast.error("Passwords do not match");
        return;
      }

      if (checked) {
        registerVendor(vendor, history);
      }
    } catch (error) {}
  };  

  const generatePDF = () => {
    getPdfFile();
  };


  return (
    <>
      <div className={classNames(classes.session, classes.background)}>
        <div className={classes.content}>
          <div className={classes.wrapper}>
            <Card>
              <CardContent>
                <form>
                  <div
                    className={classNames(classes.logo, `text-xs-center pb-xs`)}
                  >
                    <img
                      src={`https://res.cloudinary.com/www-ebaaba-com/image/upload/v1682029596/ebaaba-logo_kzmjyx.png`}
                      style={{
                        width: "300px",
                        height: "140px",
                        verticalAlign: "middle",
                        objectFit: "cover",
                        margin: "auto",
                        filter:
                          "brightness(0) saturate(100%) invert(16%) sepia(100%) saturate(7478%) hue-rotate(207deg) brightness(95%) contrast(103%)",
                      }}
                      alt="eBaaba logo"
                      className="block"
                    />
                    <Typography variant="caption">
                      Create an app id to continue.
                    </Typography>
                  </div>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    onChange={handleChange}
                    className={classes.textField}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    id="email"
                    name="email"
                    label="Email address"
                    type="email"
                    onChange={handleChange}
                    className={classes.textField}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    id="address"
                    name="address"
                    label="Address"
                    onChange={handleChange}
                    className={classes.textField}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    id="phone_number"
                    name="phone_number"
                    label="Phone Number"
                    onChange={handleChange}
                    className={classes.textField}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    id="business_name"
                    name="business_name"
                    label="Business Name"
                    onChange={handleChange}
                    className={classes.textField}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    id="type_of_product"
                    name="type_of_product"
                    label="Type of Product"
                    onChange={handleChange}
                    className={classes.textField}
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    onChange={handleChange}
                    className={classes.textField}
                    type="password"
                    fullWidth
                    margin="normal"
                    required
                  />
                  <TextField
                    id="confirmpassword"
                    name="confirmpassword"
                    label="Confirm Password"
                    onChange={handleChange}
                    className={classes.textField}
                    type="password"
                    fullWidth
                    margin="normal"
                    required
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="checkedA"
                        checked={checked}
                        onChange={handleChecked}
                      />
                    }
                    label="I have read and agree to the Terms and Conditions"
                    className={classes.fullWidth}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    disabled={!checked}
                    style={buttonStyle}
                    onClick={handleSubmit}
                  >
                    {/* Create your account */}
                    Continue
                  </Button>
                  <Typography style={{ margin: "10px 0 -10px" }}>
                    By continuing, you accept our{" "}
                    <Link
                      href="#"
                      style={{ textDecoration: "underline" }}
                      onClick={generatePDF}
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>

                  <div className="pt-1 text-xs-center">
                    <Link to="/forgot">
                      <Button>Forgot password?</Button>
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to="/signin">
                      <Button>Access your account.</Button>
                    </Link>
                  </div>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
