import axios from "axios";

// let url = process.env.REACT_APP_DEVELOPMENT_API_URL;

let url = "";

if(process.env.NODE_ENV === "development") {
    url = process.env.REACT_APP_DEVELOPMENT_API_URL;
}else {
    url = process.env.REACT_APP_PRODUCTION_API_URL;
}

const axiosConfig = axios.create({
    baseURL: url,
    mode: 'no-cors',
    headers: {
        'Content-Type' : 'application/json',
        'Access-Control-Allow-Origin' : '*',
    },
    credentials: "include",
    withCredentials: true,
});


export default axiosConfig;