import { ActivityStream, Wrapper } from "../../components";
import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
import { getUserProfile } from "../../services/ProfileServices";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  avatar: {
    display: "inline-block",
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      width: 128,
      height: 128,
    },
    [theme.breakpoints.down("xs")]: {
      width: 64,
      height: 64,
    },
    marginBottom: theme.spacing(1),
  },
  tabRoot: {
    textTransform: "initial",
    color: theme.palette.text.primary,
  },
  postInput: {
    border: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1) * 3,
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1) * 2,
    },
    fontSize: "13px",
    outline: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

const TabContainer = (props) => (
  <Typography component="div" className="pa-0">
    {props.children}
  </Typography>
);

const Profile = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const handleTabToggle = (event, tab) => setTab(tab);

  const dispatch = useDispatch();

  useEffect(() => {
    getUserProfile(dispatch);
  }, []);

  const { pending, profiles } = useSelector((state) => state.profile);

 if(pending){
    return <Loading />;
 }

  return (
    <Wrapper>
      <Grid container spacing={1} style={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <Typography variant="h6" gutterBottom>
                    {profiles ? profiles?.name : ''}
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    {profiles ?  profiles?.phone_number : ''}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {profiles ? profiles?.type : ''}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <Link to="/">{profiles ? profiles?.email : ''}</Link>
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <Link to="/">{profiles ? profiles?.address : ''}</Link>
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <Link to="/">{profiles ? profiles?.business_name : ''}</Link>
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <Link to="/">{profiles ? profiles?.type_of_product : ''}</Link>
                  </Typography>
                  <Button variant="contained" color="primary">
                    <Link to="/edit-profile">Edit Profile</Link>
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <div className="text-xs-center">
                    <Avatar
                      alt="Adelle Charles"
                      src={`${process.env.PUBLIC_URL}/static/avatar.png`}
                      className={classNames(classes.avatar)}
                    />
                    {/* <Typography variant="caption" className={"mb-xs"}>
                      Account Usage
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={40}
                      className={"mb-xs"}
                    />
                    <Typography variant="caption">234Gb of 879Gb</Typography> */}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
            {/* <Divider /> */}
            {/* <CardContent className="text-xs-center">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography variant="h6" gutterBottom>
                    23,8K
                  </Typography>
                  <Typography variant="caption">Followers</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" gutterBottom>
                    569
                  </Typography>
                  <Typography variant="caption">Following</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" gutterBottom>
                    67
                  </Typography>
                  <Typography variant="caption">Posts</Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>
                About
              </Typography>
              <Typography>
                Maecenas sed diam eget risus varius blandit sit amet non magna.
                Curabitur blandit tempus porttitor. Vestibulum id ligula porta
                felis euismod semper.
              </Typography>
            </CardContent> */}
          </Card>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Profile;
