import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "products",
  initialState: {
    orders: [],
    totalOrders: 0,
    todayOrders: 0,
    pendingOrders: 0,
    completedOrders: 0,
    pending: false,
    orderUpdated: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleOrderRequest: (state) => {
      state.pending = true;
      state.orderUpdated = true;
    },
    createOrderSuccess: (state) => {
      state.pending = false;
      state.orderUpdated = true;
      state.error = false;
      state.errorMsg = "";
    },
    getOrderSuccess: (state, action) => {
      state.pending = false;
      state.orders = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getTotalOrderSuccess: (state, action) => {
      state.pending = false;
      state.totalOrders = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getTodayOrderSuccess: (state, action) => {
      state.pending = false;
      state.todayOrders = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getPendingOrderSuccess: (state, action) => {
      state.pending = false;
      state.pendingOrders = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getCompletedOrderSuccess: (state, action) => {
      state.pending = false;
      state.completedOrders = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleOrderError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.orderUpdated = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleOrderRequest,
  createOrderSuccess,
  getOrderSuccess,
  getTotalOrderSuccess,
  getTodayOrderSuccess,
  getPendingOrderSuccess,
  getCompletedOrderSuccess,
  handleOrderError,
} = orderSlice.actions;
export default orderSlice.reducer;
