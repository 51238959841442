import {
  BackendError,
  Lockscreen,
  NotFound,
  PasswordReset,
  Signin,
  Signup,
  ChangePassword
} from "./pages";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AppProvider from "./components/AppProvider/AppProvider";
import Dashboard from "./containers/Dashboard";
import React from "react";
import registerServiceWorker from "./registerServiceWorker";
import { render } from "react-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TermsAndConditions from "components/TermsAndConditions/TermsAndConditions";

render(
  <AppProvider>
    <ToastContainer />
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/500" component={BackendError} />
          <Route exact path="/Lockscreen" component={Lockscreen} />
          <Route exact path="/forgot" component={PasswordReset} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/change-password" component={ChangePassword}/>
          <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
          <Route path="/" component={Dashboard} />
        </Switch>
      </BrowserRouter>
    </Provider>
  </AppProvider>,
  document.getElementById("root")
);

registerServiceWorker();
