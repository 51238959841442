import {
  StatCard,
  Wrapper,
} from "../../components";
import React, { useEffect, useState } from "react";

import EmailIcon from "@material-ui/icons/Email";
import Grid from "@material-ui/core/Grid";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PhoneIcon from "@material-ui/icons/Phone";
import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import "./style.css";
import { Link } from "react-router-dom";
import {
  getAllOrders,
  getAllVendorOrders,
  getTotalOrders,
  getTotalVendorOrders,
  getTodayOrders,
  getVendorTodayOrders,
  getPendingOrders,
  getVendorPendingOrders,
  getCompletedOrders,
  getVendorCompletedOrders,
} from "../../services/OrderServices";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";

const useStyles = makeStyles((theme) => ({
  paginationMenu: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 10px",
    listStyleType: "none",
  },
  menuItem: {
    margin: "0 15px",
    listStyleType: "none",
    display: "none",
  },
}));

const Orders = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState({ page: 1 });
  const [pagination, setPagination] = useState({ last_page: 0 });
  const { pending, totalOrders, todayOrders, pendingOrders, completedOrders } =
    useSelector((state) => state.order);
  const { auth } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchAllOrders();
    if (auth.type === "admin" || auth.type==="SuperAdmin" || auth.type==="Admin") {
      getTotalOrders(dispatch);
      getTodayOrders(dispatch);
      getPendingOrders(dispatch);
      getCompletedOrders(dispatch);
    }

    if (auth.type === "vendor") {
      getTotalVendorOrders(dispatch);
      getVendorTodayOrders(dispatch);
      getVendorPendingOrders(dispatch);
      getVendorCompletedOrders(dispatch);
    }
  }, [page]);

  const fetchAllOrders = async () => {
    try {
      let response;

      if (auth.type === "admin" || auth.type=== "SuperAdmin" || auth.type==="Admin") {
        response = await getAllOrders(dispatch, page.page);
      }

      if (auth.type === "vendor") {
        response = await getAllVendorOrders(dispatch, page.page);
      }

      let orders = [];
      response.data.data?.map((item) => {
        orders.push(item);
      });
      setOrders([...orders]);
      setPagination({ last_page: response.data?.last_page });
    } catch (error) {}
  };

  const next = () => {
    let currentPage = Number(page.page) + 1;
    setPage({ page: currentPage });
    window.scrollTo(0, 0);
    props.history.push("/orders/manage-orders?page=" + currentPage);
  };

  const prev = () => {
    let currentPage = Number(page.page) - 1;
    if (currentPage === 0) {
      setPage({ page: 1 });
      window.scrollTo(0, 0);
      props.history.push("/orders/manage-orders?page=" + currentPage);
    } else {
      setPage({ page: currentPage });
      window.scrollTo(0, 0);
      props.history.push("/orders/manage-orders?page=" + currentPage);
    }
  };

  if (pending) {
    return <Loading />;
  }

  const renderPagination = () => {
    const currentPage = Number(page.page);
    const lastPage = Number(pagination.last_page);
    const pagesToShow = 1; // Number of pages to show before the ellipsis
  
    const paginationItems = [];
  
    // First page
    if (currentPage !== 1) {
      paginationItems.push(
        <Button
          key={1}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(1)}
          style={{ marginRight: "10px" }}
        >
          1
        </Button>
      );
    }
  
    // If current page is not too close to the beginning, add ellipsis
    if (currentPage > pagesToShow + 1) {
      paginationItems.push(<span key="ellipsis1">...</span>);
    }
  
    // Pages before current page
    for (let i = Math.max(2, currentPage - pagesToShow); i < currentPage; i++) {
      paginationItems.push(
        <Button
          key={i}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(i)}
          style={{ margin: "auto 10px" }}
        >
          {i}
        </Button>
      );
    }
  
    // Current page
    paginationItems.push(
      <Button
        key={currentPage}
        variant="contained"
        className="pageLink"
        disabled
        style={{ marginRight: "10px" }}
      >
        {currentPage}
      </Button>
    );
  
    // Pages after current page
    for (
      let i = currentPage + 1;
      i <= Math.min(currentPage + pagesToShow, lastPage - 1);
      i++
    ) {
      paginationItems.push(
        <Button
          key={i}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(i)}
          style={{ marginRight: "10px" }}
        >
          {i}
        </Button>
      );
    }
  
    // If current page is not too close to the end, add ellipsis
    if (lastPage - currentPage > pagesToShow) {
      paginationItems.push(<span key="ellipsis2">...</span>);
    }
  
    // Last page
    if (currentPage !== lastPage) {
      paginationItems.push(
        <Button
          key={lastPage}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(lastPage)}
          style={{ margin: "auto 10px" }}
        >
          {lastPage}
        </Button>
      );
    }
  
    return paginationItems;
  };
  

  const handlePageChange = (pageNumber) => {
    setPage({ page: pageNumber });
    window.scrollTo(0, 0);
    props.history.push(`/orders/manage-orders?page=${pageNumber}`);
  };

  const statusProcessing = {
    padding: "5px 20px",
    backgroundColor: "#33b27b",
    borderRadius: "5px",
    color: "#fff",
  };

  const statusDelivered = {
    padding: "5px 20px",
    backgroundColor: "#f5f5f5",
    borderRadius: "5px",
    color: "#000",
  };

  const statusConfirmed = {
    padding: "5px 20px",
    backgroundColor: "#3F51B5",
    borderRadius: "5px",
    color: "#fff",
  };

  const statusOnTheWay = {
    padding: "5px 20px",
    backgroundColor: "#9C27B0",
    borderRadius: "5px",
    color: "#fff",
  };

  const statusCancel = {
    padding: "5px 20px",
    backgroundColor: "#F44336",
    borderRadius: "5px",
    color: "#fff",
  };

  return (
    <Wrapper>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="All Orders"
            value={totalOrders ? totalOrders : 0}
            icon={<LocalOfferIcon />}
            color="#3f51b5"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Orders Today"
            value={todayOrders ? todayOrders : 0}
            icon={<PhoneIcon />}
            color="#9c27b0"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Pending Orders"
            value={pendingOrders ? pendingOrders : 0}
            icon={<NotificationsIcon />}
            color="#f44336"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Completed"
            value={completedOrders ? completedOrders : 0}
            icon={<EmailIcon />}
            color="#ffd740"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Paper
            style={{ width: "100%", marginTop: "20px", overflowX: "auto" }}
          >
            <Table style={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Order ID</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell numeric>Total</TableCell>
                  <TableCell numeric>Profit</TableCell>
                  <TableCell numeric>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.length >= 1 ? (
                  orders?.map((value, index) => {
                    const { products } = value;
                    let totalSale = 0;
                    auth.type === "vendor" &&
                      products?.map((product) => {
                        totalSale += Number(product.qty * product?.salePrice);
                      });
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Link
                            to={`/edit-order/${value.order_number}`}
                            style={{ color: "#3F51B5" }}
                          >
                            {" "}
                            {value.order_number}
                          </Link>
                        </TableCell>
                        <TableCell>{value.created_at}</TableCell>
                        <TableCell>{value.full_name}</TableCell>
                        <TableCell numeric>
                          {auth?.type === "admin" || auth.type==="SuperAdmin" || auth.type==="Admin"
                            ? new Intl.NumberFormat().format(value.total)
                            : new Intl.NumberFormat().format(totalSale)}
                        </TableCell>
                        <TableCell numeric>
                          {auth?.type === "admin" || auth.type==="SuperAdmin" || auth.type==="Admin"
                            ? new Intl.NumberFormat().format(
                                (Number(value.total) * 10) / 100
                              )
                            : new Intl.NumberFormat().format(
                                (Number(totalSale) * 10) / 100
                              )}
                        </TableCell>
                        <TableCell>
                          <span
                            style={
                              value.order_status === "Delivered"
                                ? statusDelivered
                                : value.order_status === "Processing"
                                ? statusProcessing
                                : value.order_status === "Confirmed"
                                ? statusConfirmed
                                : value.order_status === "On the Way"
                                ? statusOnTheWay
                                : value.order_status === "Cancelled"
                                ? statusCancel
                                : null
                            }
                          >
                            {value.order_status}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <Typography variant="h6">No Orders</Typography>
                )}
              </TableBody>
            </Table>
            {pagination.last_page >= 2 ? (
              <>
                <nav>
                  <ul className="paginationMenu">
                    <li className={"menuItem"}>
                      <Button
                        variant="outlined"
                        disabled={Number(page.page) === 1}
                        className="pageLink"
                        onClick={prev}
                        style={{ marginRight: "10px" }}
                      >
                        Previous
                      </Button>
                    </li>
                    {renderPagination()}
                    <li className="menuItem">
                      <Button
                        variant="outlined"
                        className="pageLink"
                        disabled={
                          Number(page.page) === Number(pagination.last_page)
                        }
                        onClick={next}
                      >
                        Next
                      </Button>
                    </li>
                  </ul>
                </nav>
              </>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Orders;
