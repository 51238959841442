import { ActivityStream, Wrapper } from "../../components";
import React, { useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Swal from "sweetalert2";
import AddCategory from "./AddCategory";
import EditSubCategory from "./EditSubCategory";
import { toast } from 'react-toastify';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, deleteCategory, deleteSubCategory } from "../../services/CategoryService";
import EditCategory from "./EditCategory";


const useStyles = makeStyles((theme) => ({
  avatar: {
    display: "inline-block",
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      width: 128,
      height: 128,
    },
    [theme.breakpoints.down("xs")]: {
      width: 64,
      height: 64,
    },
    marginBottom: theme.spacing(1),
  },
  tabRoot: {
    textTransform: "initial",
    color: theme.palette.text.primary,
  },
  postInput: {
    border: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1) * 3,
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1) * 2,
    },
    fontSize: "13px",
    outline: 0,
    backgroundColor: theme.palette.background.paper,
  },
  snackbar: {
    margin: theme.spacing(1),
  },
}));

const TabContainer = (props) => (
  <Typography component="div" className="pa-0">
    {props.children}
  </Typography>
);

// function to edit and delete sub category item
const ActionSubCategory = ({ subcategory_id, handleDelete, handleEdit }) => {
  
  return (
    <div>
      <Button onClick={() => handleEdit()} color="primary" style={{color: 'white'}} size="small">
        <EditIcon />
      </Button>
      <Button
        onClick={() => handleDelete(subcategory_id)}
        color="secondary"
        size="small"
      >
        <DeleteIcon />
      </Button>
    </div>
  );
};

// function to edit and delete category item
const ActionCategory = ({ category_id, handleDelete, handleEdit }) => {

  return (
    <div>
      <Button onClick={() => handleEdit()} color="primary" style={{color: 'white'}} size="small">
        <EditIcon />
      </Button>
      <Button
        onClick={() => handleDelete(category_id)}
        color="secondary"
        size="small"
      >
        <DeleteIcon />
      </Button>
    </div>
  );
};

const ManageCategories = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { pending, created, deleted, categories } = useSelector(state => state.category);

  useEffect(() => {
    getCategories(dispatch);
  }, [created, deleted]);

  const [options, setOptions] = useState([{ label: "", value: "", id: "" }]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [subCategoryId, setSubcategoryId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const handleOptionSelected = (event, newValue) => {
    setSelectedOption(newValue);
  };
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  }
  const [toggleSubCategory, setToggleSubCategory] = useState(false);
  const handleToggleSubCatgory = (index) => {
    setToggleSubCategory(!toggleSubCategory);
    setSubcategoryId(index);
  }

  const [toggleCategory, setToggleCategory] = useState(false);
  const handleToggleCategory = (index) => {
    setToggleCategory(!toggleCategory);
    setCategoryId(index);
  }

  const handleDeleteCategory =  (index) => {
    try {
     deleteCategory(index, dispatch);
    }catch(error) {
      
    }
  }

  const handleDeleteSubCategory = (index) => {
    try {
      deleteSubCategory(index, dispatch);
     }catch(error) {
     }
  }

  // Fetch categories and saves it on the options state
  const fetchCategories = () => {
    const temp = [];
    categories?.map((category) => {
      temp.push({
        label: category?.category_name,
        value: category?.category_name,
        id: category?.id,
      });
    });

    setOptions(temp);
  };

  useEffect(() => {
    fetchCategories();
  }, [selectedOption, pending]);

  const selectedCategory = categories?.filter(
    (category) => category?.id == selectedOption?.id
  );

  return (
    <Wrapper>
      <Grid container spacing={1} style={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardContent
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button onClick={() => handleToggle()} variant="contained" color="primary">
                Add Category
              </Button>
              <Autocomplete
                style={{ width: "200px", height: "auto" }}
                options={options}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                value={selectedOption}
                onChange={handleOptionSelected}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Select Category"
                    variant="outlined"
                    className={classes.textField}
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </CardContent>
            <Divider />
            <CardContent>
              {selectedOption !== null ? (
                <>
                  {selectedCategory?.map((category, index) => {
                    const { category_name, sub_category } = category;
                    return (
                      <div key={index}>
                        <SnackbarContent
                          className={classes.snackbar}
                          style={{ backgroundColor: "#303F9F" }}
                          message={category_name}
                          action={<ActionCategory category_id={category.id} handleDelete={() => handleDeleteCategory(category.id)}
                          handleEdit={()=> handleToggleCategory(category.id)}
                          />}
                        />
                        {sub_category?.map((subcategory, index) => {
                          return (
                            <div key={index} style={{ marginLeft: "20px" }}>
                              <SnackbarContent
                                className={classes.snackbar}
                                message={subcategory.sub_category_name}
                                action={
                                  <ActionSubCategory
                                    subcategory_id={subcategory.id}
                                    handleDelete={() => handleDeleteSubCategory(subcategory.id)}
                                    handleEdit={()=> handleToggleSubCatgory(subcategory.id)}
                                  />
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </>
              ) : (
                <h3>No Category Selected</h3>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Add Category Modal */}
      <AddCategory show={handleToggle} close={toggle} />
      {/* Edit Category Modal */}
      <EditCategory show={handleToggleCategory} close={toggleCategory} categoryId={categoryId} />
      {/* Edit Sub Category Modal */}
      <EditSubCategory show={handleToggleSubCatgory} close={toggleSubCategory} subCategoryId={subCategoryId}  />
    </Wrapper>
  );
};

export default ManageCategories;
