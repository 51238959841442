import http from "../http-common";
import {
  loginError,
  loginErrorVerify,
  loginRequest,
  loginSuccess
} from "../redux/Slices/Auth";
import { toast } from "react-toastify";


export const loginUser = (user, dispatch, history) => {
  dispatch(loginRequest());
  let verify = 0;

  http
    .post("admin-login", user)
    .then((response) => {
      if (response.data.authorized) {
        localStorage.setItem("isAuthenticated", true);
        verifyUser(dispatch, history, verify);
      } else {
        toast.error(response.data.message);
        dispatch(loginError());
      }
    })
    .catch((error) => {
      toast.error("Logging error");
      dispatch(loginError());
      dispatch(loginError(error));
    });
};

//From token || localStorage, verify user session.
export const verifyUser = (dispatch, history, verify) => {
  if (!localStorage.getItem("isAuthenticated")) {
    history.push("/signin");
  }

  http
    .get("user")
    .then((response) => {
      if (response.data.success) {
        dispatch(loginSuccess(response.data.data));
        if (!verify) {
          history.push("/");
          toast.success('Login success');
        }
      }else{
        dispatch(loginError());
        history.push('/signin');
      }
    })
    .catch((error) => {
      toast.error(error.response);
      dispatch(loginErrorVerify({ error, history }));
    });
};



export const registerVendor = async (vendor, history) => {
  http
    .post(`register-vendor`, vendor)
    .then((response) => {
      if(response.data.success){
        toast.success(response.data.message);
        history.push('/signin');
      }else{
        toast.error(response.data.message);
      }
    })
    .catch((err) => {
      toast.error("Error! Registration fails");
    });
};

export const checkAuth  = async (history) => {
  http.get('authenticated')
  .then((response) => {
    if(response.success){
      history.push('/');
      return;
    }
  }).catch((error) => {});
}

