import React, { useState, useEffect } from "react";
import "./multi-step-style.css";
import { Button } from "@material-ui/core";
import { updateOrderStatus } from "../../services/OrderServices";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MultiStepButtons = ({ orderStatus, orderNumber, pending }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const steps = ["Processing", "Confirmed", "On the Way", "Delivered"];
  const [completedSteps, setCompletedSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    // Set completed steps based on order status
    const statusToStep = {
      Processing: 0,
      Confirmed: 1,
      "On the Way": 2,
      Delivered: 3,
    };
    const currentStepIndex = statusToStep[orderStatus];
    if (currentStepIndex !== undefined) {
      setCurrentStep(currentStepIndex);
      setCompletedSteps([...Array(currentStepIndex + 1).keys()]);
    }
  }, [orderStatus, pending]);

  const handleStepClick = async (stepIndex) => {
    try {
      if (stepIndex === currentStep + 1) {
        setCurrentStep(stepIndex);
        setCompletedSteps([...completedSteps, stepIndex]);
        updateOrderStatus(orderNumber, steps[stepIndex - 1], dispatch, history);
      }
    } catch (error) {
      // Handle errors if needed
    }
  };

  return (
    <div className="step-buttons-container">
      {steps?.map((step, index) => (
        <div key={index} className="step-button-wrapper">
          <Button
            onClick={() => handleStepClick(index)}
            className={
              `button-steps ${completedSteps.includes(index)
                ? "completed"
                : index === currentStep
                ? "current"
                : ""
              }`}
            disabled={index === 0 || completedSteps.includes(index)}
          >
            {step}
          </Button>
          {index < steps.length - 1 && (
            <div
              className={`separator ${
                completedSteps.includes(index  + 1) ? "completed" : ""
              }`}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MultiStepButtons;
