import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
  name: "profiles",
  initialState: {
    profiles: {},
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleProfileRequest: (state) => {
      state.pending = true;
    },
    createProfileSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    getProfileSuccess: (state, action) => {
      state.pending = false;
      state.profiles = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleProfileError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleProfileRequest,
  createProfileSuccess,
  getProfileSuccess,
  handleProfileError,
} = profileSlice.actions;
export default profileSlice.reducer;
