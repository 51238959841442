import { Box, Modal, Typography } from "@material-ui/core";
import * as React from "react";
import "./style.css";
import { useState } from "react";
import { getSingleUserDetails } from "services/UserManagementService";
import { useEffect } from "react";
import Loading from "components/Loading/Loading";
import CloseIcon from "@material-ui/icons/Close";

export default function UserInfoModal({ close, show, hoveredIndex }) {
  const handleClose = () => close(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getUserInformation = async () => {
      try {
        setLoading(true);
        const response = await getSingleUserDetails(hoveredIndex);
        setData(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    if (hoveredIndex !== null) {
      getUserInformation();
    }
  }, [hoveredIndex]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-content">
        <div className="modal-header">
          <Typography className="modal-modal-title" variant="h6" component="h3">
            User Information
          </Typography>
          <CloseIcon className="modal-icon"  onClick={close} />
        </div>

        <div className="popup">
          <div className="content">
            <span>Name: </span>
            <span> {data ? data.name : ""}</span>
          </div>
          <div className="content">
            <span>Address: </span>
            <span> {data ? data.address : ""}</span>
          </div>
          <div className="content">
            <span>Business Name: </span>
            <span> {data ? data.business_name : ""}</span>
          </div>
          <div className="content">
            <span>Tel. Number: </span>
            <span> {data ? data.phone_number : 0}</span>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
