import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "categories",
  initialState: {
    categories: [],
    pending: false,
    created: false,
    deleted: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleCategoryRequest: (state) => {
      state.pending = true;
    },
    createCategorySuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.created = true;
      state.deleted = true;
      state.errorMsg = "";
    },
    getCategorySuccess: (state, action) => {
      state.pending = false;
      state.created = false;
      state.deleted = false;
      state.categories = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleCategoryError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.created = false;
      state.deleted = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleCategoryRequest,
  createCategorySuccess,
  getCategorySuccess,
  handleCategoryError,
} = categorySlice.actions;
export default categorySlice.reducer;
