import { Cloudinary as CoreCloudinary, Util } from 'cloudinary-core';

export const url = (publicId, options) => {
  const scOptions = Util.withSnakeCaseKeys(options);
  const cl = CoreCloudinary.new();
  return cl.url(publicId, scOptions);
};

export const openUploadWidget = (options, callback) => {
  const scOptions = Util.withSnakeCaseKeys(options);

  const cloudinaryWidgetLoaded = () => {
    if (window.cloudinary && window.cloudinary.openUploadWidget) {
      window.cloudinary.openUploadWidget(scOptions, callback);
    } else {
      console.error('Error: Cloudinary widget library not loaded.');
    }
  };

  if (window.cloudinary) {
    cloudinaryWidgetLoaded();
  } else {
    // Load Cloudinary widget library dynamically
    const script = document.createElement('script');
    script.src = 'https://widget.cloudinary.com/v2.0/global/all.js';
    script.onload = cloudinaryWidgetLoaded;
    script.onerror = () => {
      console.error('Error: Failed to load Cloudinary widget library.');
    };
    document.body.appendChild(script);
  }
};
