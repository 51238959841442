import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
  } from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
  import { useState } from "react";
  import { toast } from 'react-toastify';
  import { addCategory } from "../../services/CategoryService";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
  
  export default function AddCategory({ show, close }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { pending } = useSelector(state => state.category);
    const [categoryName, setCategoryName] = useState("");
    const handleSubmit = async (event) => {
      event.preventDefault();
      try {
        addCategory(categoryName, dispatch, history);
        show();
      }catch(error){
        console.error(error);
      }
      
    };
  
    const handleChange = (e) => {
      setCategoryName(e.target.value);
    };

    if(pending){
      return <Loading />;
    }
  
    return (
      <div>
        <Dialog open={close} onClose={() => show()}>
          <DialogTitle>Add New Category</DialogTitle>
          <DialogContent style={{ width: "280px" }}>
            <TextField
              autoFocus
              margin="dense"
              label="Category Name"
              type="text"
              name="categoryName"
              id="categoryName"
              onChange={handleChange}
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => show()}>Cancel</Button>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Add Category
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  