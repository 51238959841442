import {
  Wrapper
} from "../../components";
import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  Card,
  CardContent,
  makeStyles,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { toast } from 'react-toastify';
import Loading from "../../components/Loading/Loading";
import { addUser } from "../../services/UserManagementService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
  paginationMenu: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 10px",
    listStyleType: "none",
  },
  menuItem: {
    margin: "0 15px",
    listStyleType: "none",
    display: "none",
  },
  card: {
    position: "relative",
    clear: "both",
  },
  appBar: {
    boxShadow: theme.shadows[0],
  },
  button: {
    display: "block",
    marginTop: theme.spacing(1) * 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddUser = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    type: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { pending } = useSelector(state => state.user);
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [errorCreatingUser, setErrorCreatingUser] = useState(false);
  const [email, setEmailExists] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);

  const handleChange = (event) => {
    setUser(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
  };


  const handleRegister = async (event) => {
    event.preventDefault();
   
    if(user.password !== user.confirmPassword){
      toast.error('Password do not match');
      return;
    }

    try {
      addUser(user, dispatch, history);
    }catch(error) {

    }

  };

  if(pending){
    return <Loading />;
  }
  return (
    <Wrapper className={classes.root}>
      <Card className={classes.card}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography color="inherit" variant="h6" className="flexSpacer">
              Add User
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent className={classes.content}>
          <form onSubmit={handleRegister}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  type="text"
                  id="name"
                  name="name"
                  label="Name"
                  onChange={handleChange}
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  required
                  autoComplete="name"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  type="email"
                  id="email"
                  name="email"
                  label="Email"
                  className={classes.textField}
                  fullWidth
                  margin="normal"
                  onChange={handleChange}
                  autoComplete="email"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  className={classes.textField}
                  type="password"
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  autoComplete="password"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password"
                  className={classes.textField}
                  onChange={handleChange}
                  type="password"
                  autoComplete="new-password"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="type">Select User Role</InputLabel>
                  <Select
                    id="type"
                    name="type"
                    value={user.type}
                    onChange={handleChange}
                    label="type"
                  >
                    <MenuItem value=''>Choose..</MenuItem>
                    <MenuItem value='SuperAdmin'>SuperAdmin</MenuItem>
                    <MenuItem value='Admin'>Admin</MenuItem>
                    <MenuItem value='vendor'>Vendor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              style={{
                width: "160px",
                margin: "20px auto 0 auto",
                display: "flex",
              }}
            >
              Add User
            </Button>
          </form>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default AddUser;
