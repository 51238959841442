import http from "../http-common";
import {
  handleUserManagementRequest,
  createUserManagementSuccess,
  getUserProfileSuccess,
  getUserManagementSuccess,
  handleUserManagementError,
} from "../redux/Slices/UserManagementSlice";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";

export const getAllUsers = async (dispatch, pageNumber) => {
  dispatch(handleUserManagementRequest());
  const data = await http
    .get(`auth-users?page=${pageNumber}`)
    .then((response) => {
      dispatch(createUserManagementSuccess());
      return response;
    })
    .catch((error) => {
      dispatch(handleUserManagementError());
      return error;
    });

  return data;
};

export const getSingleUser = async (userID, dispatch) => {
  dispatch(handleUserManagementRequest());
  const data = await http.get(`user/${userID}`)
  .then((response) => {
    if(response?.data){
      dispatch(createUserManagementSuccess());
    }
    return response?.data;
  }).catch((error) => {
    dispatch(handleUserManagementError());
    return error;
  });

  return data;
}

export const getSingleUserDetails = async (userID) => {
  const data = await http.get(`user-details/${userID}`)
  .then((response) => {
    return response?.data;
  }).catch((error) => {
    return error;
  });

  return data;
}

export const deleteUser = async (email, userRole, token, dispatch) => {
  dispatch(handleUserManagementRequest());
  Swal.fire({
    title: "Are you sure?",
    text: "This action is not revertable",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        let response = await http.post(
          "admins-customers-delete?token=" + token,
          {
            data: { email: email, userRole: userRole },
          }
        );

        let data = response.data;

        if (data.Deleted) {
          toast.success("User Deleted!");
          dispatch(createUserManagementSuccess());
          return;
        }
        toast.error("Error! Deleting User!");
        dispatch(handleUserManagementError());
      } catch (error) {
        toast.error("Error! Deleting User!");
        dispatch(handleUserManagementError());
      }
    } else {
      dispatch(handleUserManagementError());
    }
  });
};

export const addUser = (data, dispatch) => {
  dispatch(handleUserManagementRequest());
  http
    .post("register", data)
    .then((response) => {
      if (response.data.success) {
        dispatch(createUserManagementSuccess());
        toast.success(response.data.message);
        window.scrollTo(0, 0);
      } else {
        toast.error(response.data.message.type?response.data.message.type[0] : response.data.message);
        window.scrollTo(0, 0);
        dispatch(handleUserManagementError());
      }
    })
    .catch((error) => {
      console.log(error)
      toast.error("Created user error");
      dispatch(handleUserManagementError());
    });
};

export const sendTokenEmail = (email, dispatch) => {
  dispatch(handleUserManagementRequest());
  http.post('send-token-email', {email: email })
  .then((response) => {
    console.log('Logging response === ', response);
    if(response.data.success){
      dispatch(createUserManagementSuccess());
      toast.success(response.data.message);
    }else{
      dispatch(handleUserManagementError());
      toast.error(response.data.message);
    }
   
  }).catch((error) => {
    toast.error('Error, Please try agained');
    dispatch(handleUserManagementError());
  });
}

export const resetPasswordWithToken = (token, password, dispatch, history) => {
  dispatch(handleUserManagementRequest());

  http.post(`reset-password/${token}`, {password: password})
  .then((response) => {
    if(response.data.success){
      dispatch(createUserManagementSuccess());
      toast.success(response.data.message);
      history.push('/signin');
    }else{
      dispatch(handleUserManagementError());
      toast.error(response.data.message);
    }
  }).catch((error) => {
    toast.error('Error reseting password');
  })
}

export const agreeTermsAndConditions = (userID, signature, dispatch) => {
  dispatch(handleUserManagementRequest());

  http.post('create-signature', {user_id: userID, signature: signature})
  .then((response) => {
    if(response.data.success){
      dispatch(createUserManagementSuccess());
      toast.success(response.data.message);
    }else{
      toast.error(response.data.message);
      dispatch(handleUserManagementError());
    }
   
  }).catch(error => {
    toast.error(error.response.message);
    dispatch(handleUserManagementError());
  })
}

export const getTermsAndConditionsDetails = async (userID) => {
  const data = await http.get(`agrees-with-terms/${userID}`)
  .then((response) => {
    return response;
  }).catch((error) => {
    return error;
  });

  return data;
}

export const addTermsAndConditions = (data, dispatch, history) => {
  dispatch(handleUserManagementRequest());
  http
    .post("add-terms-and-conditions-content", {content: data})
    .then((response) => {
      if (response.data.success) {
        dispatch(createUserManagementSuccess());
        toast.success(response.data.message);
        history.push('/manage-terms-and-conditions');
      } else {
        toast.error(response.data.message);
        dispatch(handleUserManagementError());
      }
    }).catch((error) => {
      dispatch(handleUserManagementError());
    });
};

export const getTermsAndConditions = async () => {
  const data = await http.get(`terms-and-conditions-content`)
  .then((response) => {
    return response.data.message;
  }).catch((error) => {
    return error;
  });

  return data;
}

const generatePDF = async () => {
  try {
    const response = await fetch('http://your-api-url/convertToPDF');
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();

    // Create a URL for the Blob and open it in a new tab
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, '_blank');

    // Clean up the URL object after the new tab is opened
    URL.revokeObjectURL(pdfUrl);
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};


export const getPdfFile = async () =>{
  try {
    const response = await http.get('terms-and-conditions-pdf', {
      responseType: 'blob', // This specifies that the response should be treated as a binary blob
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });

    // Create a URL for the Blob and open it in a new tab
    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, '_blank');

    // Clean up the URL object after the new tab is opened
    URL.revokeObjectURL(pdfUrl);
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
}

export const updateTermsAndConditions = (data, dispatch, history) => {
  dispatch(handleUserManagementRequest());
  http
    .put("update-terms-and-conditions-content", {content: data})
    .then((response) => {
      if (response.data.success) {
        dispatch(createUserManagementSuccess());
        toast.success(response.data.message);
        history.push('/manage-terms-and-conditions');
      } else {
        toast.error(response.data.message);
        dispatch(handleUserManagementError());
      }
    }).catch((error) => {
      dispatch(handleUserManagementError());
    });
};