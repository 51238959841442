import { createSlice } from "@reduxjs/toolkit";

export const vendorSlice = createSlice({
  name: "vendors",
  initialState: {
    vendors: {},
    totalVendors: null,
    totalApprovedVendors: null,
    totalPendingVendors: null,
    totalDeactivatedVendors: null,
    deleteSuccess: false,
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleVendorRequest: (state) => {
      state.pending = true;
    },
    createVendorSuccess: (state) => {
      state.pending = false;
   
      state.error = false;
      state.errorMsg = "";
    },
    getVendorSuccess: (state, action) => {
      state.pending = false;
      state.vendors = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getTotalVendorSuccess: (state, action) => {
      state.pending = false;
      state.totalVendors = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getTotalApprovedVendorSuccess: (state, action) => {
      state.pending = false;
      state.totalApprovedVendors = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getTotalPendingVendorSuccess: (state, action) => {
      state.pending = false;
      state.totalPendingVendors = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getTotalDeactivatedVendorSuccess: (state, action) => {
      state.pending = false;
      state.totalDeactivatedVendors = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleVendorError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    createDeleteSuccess: (state) => {
      state.deleteSuccess = true;
    }
  },
});

export const {
  handleVendorRequest,
  createVendorSuccess,
  getVendorSuccess,
  getTotalVendorSuccess,
  getTotalApprovedVendorSuccess,
  getTotalPendingVendorSuccess,
  getTotalDeactivatedVendorSuccess,
  handleVendorError,
  createDeleteSuccess
} = vendorSlice.actions;
export default vendorSlice.reducer;
