import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Wrapper, Example } from "../../components";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  AppBar,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
// import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import "./style.css";
import { openUploadWidget } from "../utils/CloudinaryService";
import { slug, chunk } from "../utils/UtilityFunctions";
import AddIcon from "@material-ui/icons/Add";
// import MinusIcon from '@material-ui/icons/Minus';
import Icon from "@material-ui/core/Icon";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/Loading";
import { addProduct } from "../../services/ProductService";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDeliveryCharges } from "../../services/DeliveryChargeService";
import EditDelivery from "./EditDelivery";

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center",
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
  },
  fullWidth: {
    width: "100%",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  root: {
    paddingBottom: theme.spacing(1),
  },
  paginationMenu: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 10px",
    listStyleType: "none",
  },
  menuItem: {
    margin: "0 15px",
    listStyleType: "none",
    display: "none",
  },
  card: {
    position: "relative",
    clear: "both",
  },
  appBar: {
    boxShadow: theme.shadows[0],
  },
  button: {
    display: "block",
    marginTop: theme.spacing(1) * 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddDelivery = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { pending, created, deliveryCharges } = useSelector(state => state.deliveryCharge);

  useEffect(() => {
    getDeliveryCharges(dispatch);
  }, [created]);
 
  const [deliveryChargeId, setDeliveryChargeId] = useState(null);
  const [toggleDeliveryCharge, setToggleDeliveryCharge] = useState(false);

  const handleToggleDeliveryCharge = (index) => {
    setToggleDeliveryCharge(!toggleDeliveryCharge);
    setDeliveryChargeId(index);
  };


  return (
    <Wrapper className={classes.root}>
      <Card className={classes.card}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography color="inherit" variant="h6" className="flexSpacer">
              Manage Deliveries
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent className={classes.content}>
          <Paper
            style={{ width: "100%", marginTop: "20px", overflowX: "auto" }}
          >
            <Table style={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell numeric>ID#: </TableCell>
                  <TableCell>Key</TableCell>
                  <TableCell numeric>Value</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  deliveryCharges.length >= 1 ? deliveryCharges.map((deliveryCharge, index) => {
                    return (
                      <TableRow key={index}>
                      <TableCell numeric>{deliveryCharge?.id}</TableCell>
                      <TableCell>{deliveryCharge?.key}</TableCell>
                      <TableCell numeric>{deliveryCharge?.value}</TableCell>
                      <TableCell >{deliveryCharge?.type}</TableCell>
                      <TableCell>
                        <Link
                          to="#"
                          onClick={() => handleToggleDeliveryCharge(deliveryCharge?.id)}
                          style={{ color: "#3F51B5", marginRight: '10px'}}
                        >
                          <EditIcon />
                        </Link>
                      </TableCell>
                    </TableRow>
                    )
                  }) : !pending ? <Typography>No Delivery Charges</Typography> : null
                }
              
              </TableBody>
            </Table>
          </Paper>
        </CardContent>
      </Card>

      <EditDelivery
        show={handleToggleDeliveryCharge}
        close={toggleDeliveryCharge}
        deliveryChargeId={deliveryChargeId}
        pending={pending}
      />
    </Wrapper>
  );
};

export default AddDelivery;
