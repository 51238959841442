import {
  Wrapper,
} from "../../components";
import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import PrintIcon from "@material-ui/icons/Print";
import moment from "moment";
import Loading from "../../components/Loading/Loading";
import "./order-style.css";
import {
  Card,
  CardContent,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  getOrder,
  completeOrder,
  cancelOrder,
  deleteOrder,
} from "../../services/OrderServices";
import {
  getSingleUser
} from "../../services/UserManagementService";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import Notification from "../../components/Notification/Notification";
import UserInfoModal from "./UserInfoModal.js";
import MultiStepButtons from "./MultiStepButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
  paginationMenu: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 10px",
    listStyleType: "none",
  },
  menuItem: {
    margin: "0 15px",
    listStyleType: "none",
    display: "none",
  },
  card: {
    position: "relative",
    clear: "both",
  },
  appBar: {
    boxShadow: theme.shadows[0],
  },
}));

const EditOrder = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderID } = useParams();
  const [order, setOrder] = useState({
    products: [],
  });
  const [isRegistered, setIsRegistered] = useState("");
  const [loading, setLoading] = useState(false);
  const { pending, orderUpdated } = useSelector((state) => state.order);
  const { auth } = useSelector((state) => state.auth);

  const pendingOrder = {
    padding: "5px 15px",
    backgroundColor: "#33b27b",
    color: "#fff",
    fontWeight: "300",
    borderRadius: "5px",
    border: "none",
  };

  const orderCompletd = {
    padding: "5px 15px",
    backgroundColor: "#f5f5f5",
    color: "#000",
    fontWeight: "300",
    borderRadius: "5px",
    border: "none",
  };

  const getOrderInformation = async () => {
    try {
      const response = await getOrder(dispatch, orderID);
      if (response.data) {
        let oInfo = response.data.order;
        
        if (oInfo) {
          let orderData = JSON.parse(oInfo.products);
          setOrder({
            ...order,
            id: oInfo.id,
            orderNumber: oInfo.order_number,
            fullName: oInfo.full_name,
            products: orderData,
            total: oInfo.total,
            updatedAt: oInfo.updated_at,
            createdAt: oInfo.created_at,
            paymentMethod: oInfo.payment_method,
            country: oInfo.country,
            address: oInfo.address,
            email: oInfo.email,
            orderNote: oInfo.order_note,
            shippingAddress: oInfo.shipping_address,
            deliveryAddress: oInfo.delivery_address,
            orderStatus: oInfo.order_status,
            phoneNumber: oInfo.phone_number,
            townCity: oInfo.town_city,
            modeOfDelivery: oInfo.mode_of_delivery,
            deliveryFee: oInfo.delivery_fee
          });
          setIsRegistered(response.data.message);
        }
      }
    } catch (error) {}
  };

  const vendorProducts = order.products.filter(
    (product) => product.creatorID === auth.id
  );

  let total = 0;
  vendorProducts?.map((product) => {
    total += Number(product.qty * product.salePrice);
  });

  // function countUniqueCreatorIDs(arr) {
  //   const uniqueCreatorIDs = new Set();

  //   arr.forEach((item) => {
  //     uniqueCreatorIDs.add(item.creatorID);
  //   });

  //   return uniqueCreatorIDs.size;
  // }

  // const uniqueCreatorIDs = countUniqueCreatorIDs(order.products);

  const handleComplete = async () => {
    try {
      completeOrder(order.orderNumber, dispatch, history);
    } catch (error) {}
  };

  const handleCancel = async () => {
    setLoading(true);
    try {
      cancelOrder(order.orderNumber, dispatch, history);
      setLoading(true);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      deleteOrder(orderID, dispatch, history);
    } catch (error) {}
  };

  useEffect(() => {
    getOrderInformation();
  }, [orderUpdated, loading]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleOpen = (msg) => {
    setMessage(msg);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <Wrapper className={classes.root} style={{ position: "relative" }}>
      <Button
        onClick={handlePrint}
        variant="contained"
        color="primary"
        className="print-button"
      >
        <PrintIcon className="icon" />
      </Button>

      <div>
        <div className="report-header">
          <div className="col">
            <Typography variant="h4">{order?.fullName}</Typography>
            <Typography variant="h6">{order.address}</Typography>
            {auth?.type === "vendor" && (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleOpen(
                      "A customer has placed an order for your product. Please call eBaaba on +2202811871 to arrange to order delivery"
                    )
                  }
                >
                  View Order Details
                </Button>
                <Notification
                  color="primary"
                  open={open}
                  message={message}
                  handleClose={handleClose}
                />
              </div>
            )}
          </div>
          <div className="col">
            {(auth.type === "admin" || auth.type === "Admin" || auth.type === "SuperAdmin") && (
              <Typography variant="h6" style={{ textTransform: "lowercase" }}>
                {order.email}
              </Typography>
            )}
            <Typography variant="inherit">
              Updated on: {order.updatedAt}
            </Typography>
          </div>
        </div>
        <Grid container spacing={1} className="row">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card className="card report-card">
              <div className="card-header">
                {(auth?.type === "admin" || auth.type === "Admin" || auth.type === "SuperAdmin") && (
                  <Typography variant="h4">
                    Order Overview{" "}
                    <DeleteIcon
                      onClick={() => handleDelete()}
                      className="icon"
                    />{" "}
                    {order.orderStatus !== "Cancelled" && (
                      <Button
                        onClick={handleCancel}
                        style={{
                          background: "#9C27B0",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: 800,
                          marginLeft: "10px",
                          padding: "3px",
                        }}
                        disabled={order.orderStatus === "Cancelled"}
                      >
                        Cancel
                      </Button>
                    )}
                  </Typography>
                )}

                {order.orderStatus === "Cancelled" && (
                  <Typography variant="h2" style={{ color: "red" }}>
                    Cancelled
                  </Typography>
                )}

                {(auth?.type === "admin" || auth?.type === "Admin" || auth?.type === "SuperAdmin") &&
                  order.orderStatus !== "Completed" &&
                  order.orderStatus !== "Cancelled" && (
                    <MultiStepButtons
                      orderStatus={order && order?.orderStatus}
                      orderNumber={order && order?.orderNumber}
                      pending={pending}
                    />
                  )}
              </div>

              <div className="card-body">
                <CardContent className="card-content">
                  <Typography
                    variant="h6"
                    className="card-title text-secondary mb-0"
                  >
                    Order Summary{" "}
                  </Typography>
                  <div className="card-row">
                    <Typography variant="inherit">Order Number</Typography>
                    <Typography variant="inherit">
                      {order.orderNumber}
                    </Typography>
                  </div>
                  <div className="card-row">
                    <Typography variant="inherit">Order Creation</Typography>
                    <Typography variant="inherit">
                      {moment(order.createdAt).format("YYYY-MM-DD")}
                    </Typography>
                  </div>
                  {(auth.type === "admin" || auth.type === "Admin" || auth.type === "SuperAdmin") && (
                    <div className="card-row">
                      <Typography variant="inherit">Payment Method</Typography>
                      <Typography variant="inherit">
                        {order.paymentMethod}
                      </Typography>
                    </div>
                  )}

                  {auth?.type === "vendor" && (
                    <div className="card-row">
                      <Typography variant="inherit">
                        Contact eBaaba on
                      </Typography>
                      <Typography variant="inherit">+2202811871</Typography>
                    </div>
                  )}

                  {(auth?.type === "admin" || auth.type === "Admin" || auth.type === "SuperAdmin") && (
                    <>
                    <div className="card-row">
                        <Typography variant="inherit">Town / City</Typography>
                        <Typography variant="inherit">
                          {order.townCity}
                        </Typography>
                      </div>
                      <div className="card-row">
                        <Typography variant="inherit">Delivery Address</Typography>
                        <Typography variant="inherit">
                          {order.deliveryAddress}
                        </Typography>
                      </div>
                      <div className="card-row">
                        <Typography variant="inherit">Phone Number</Typography>
                        <Typography variant="inherit">
                          {order.phoneNumber}
                        </Typography>
                      </div>
                      <div className="card-row">
                        <Typography variant="inherit">Registered</Typography>
                        <Typography variant="inherit">
                          {isRegistered}
                        </Typography>
                      </div>
                      <div className="card-row">
                        <Typography variant="inherit">Order Note</Typography>
                        <Typography variant="inherit">
                          {order.orderNote}
                        </Typography>
                      </div>
                    </>
                  )}
                  <div className="card-row">
                    <Typography variant="inherit">Mode of Delivery</Typography>
                    <Typography variant="inherit">{order.modeOfDelivery}</Typography>
                  </div>
                  <div className="card-row">
                    <Typography variant="inherit">Delivery Fee</Typography>
                    <Typography variant="inherit">{order.deliveryFee}</Typography>
                  </div>
                  <div className="card-row alt">
                    <Typography variant="h5">Total</Typography>
                    <Typography variant="h5">
                      {(auth?.type === "admin" || auth.type === "Admin" || auth.type === "SuperAdmin")
                        ? new Intl.NumberFormat("en-GM", {
                            style: "currency",
                            currency: "GMD",
                          }).format(order.total)
                        : new Intl.NumberFormat("en-GM", {
                            style: "currency",
                            currency: "GMD",
                          }).format(total)}
                    </Typography>
                  </div>
                </CardContent>

                <CardContent className="card-content">
                  <Typography
                    variant="h6"
                    className="card-title text-secondary mb-0"
                  >
                    Order Items{" "}
                  </Typography>
                  <Paper
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      overflowX: "auto",
                    }}
                  >
                    <Table style={{ minWidth: 700 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Image</TableCell>
                          <TableCell>Product</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Attribute</TableCell>
                          {(auth?.type === "admin" || auth.type === "Admin" || auth.type === "SuperAdmin") && (
                            <TableCell>Sold By</TableCell>
                          )}
                          <TableCell>Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      {(auth?.type === "admin" || auth.type === "Admin" || auth.type === "SuperAdmin") ? (
                        <AdminOrders products={order.products} />
                      ) : (
                        <VendorOrders products={vendorProducts} />
                      )}
                    </Table>
                  </Paper>
                </CardContent>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div style={{ display: "none" }}>
        <main className="main-receipt" ref={componentRef}>
          <div className="container">
            <header className="header">
              <img
                className="logo"
                src="https://res.cloudinary.com/www-ebaaba-com/image/upload/v1682029596/ebaaba-logo_kzmjyx.png"
              />
            </header>
            <section className="success-section">
              <div className="icon-container">
                <div className="success-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </div>
              </div>

              <div className="success-content">
                <h3 className="heading">Your order has been placed</h3>
                <div className="info-text">
                  <p>Your order number is</p>
                  <h4 className="order-number">{orderID}</h4>
                </div>
              </div>
            </section>
            <section className="order-info">
              <header className="order-info-header">
                <h2 className="order-info-heading">Order Information</h2>
              </header>
              <div className="order-info-content">
                <table className="order-info-table">
                  <thead>
                    <tr>
                      <th>
                        <span className="info-text">Order Date</span>
                      </th>
                      <th>
                        <span className="info-text">Customer Name</span>
                      </th>
                      <th>
                        <span className="info-text">Delivery Address</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="info-text">{order.createdAt}</span>
                      </td>
                      <td>
                        <span className="info-text">{order.fullName}</span>
                      </td>
                      <td>
                        <span className="info-text">
                          {order.shippingAddress}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section className="order-summary">
              <table className="order-summary-table">
                <thead>
                  <tr>
                    <th>
                      <span className="info-text">Product</span>
                    </th>
                    <th>
                      <span className="info-text">Quantity</span>
                    </th>
                    <th>
                      <span className="info-text">Price</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {order.products?.map((product, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className="info-text">
                            {product.productName}
                          </span>
                        </td>
                        <td>
                          <span className="info-text">{product.qty}</span>
                        </td>
                        <td>
                          <span className="info-text">
                            {new Intl.NumberFormat("en-GM", {
                              style: "currency",
                              currency: "GMD",
                            }).format(product.qty * product.salePrice)}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td></td>
                    <td>Subtotal</td>
                    <td className="subtotal">
                      {auth?.type === "admin" || auth.type === "Admin" || auth.type === "SuperAdmin"
                        ? new Intl.NumberFormat("en-GM", {
                            style: "currency",
                            currency: "GMD",
                          }).format(order.total)
                        : new Intl.NumberFormat("en-GM", {
                            style: "currency",
                            currency: "GMD",
                          }).format(total)}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Total</td>
                    <td className="total">
                      {auth?.type === "admin" || auth.type === "Admin" || auth.type === "SuperAdmin"
                        ? new Intl.NumberFormat("en-GM", {
                            style: "currency",
                            currency: "GMD",
                          }).format(order.total)
                        : new Intl.NumberFormat("en-GM", {
                            style: "currency",
                            currency: "GMD",
                          }).format(total)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
        </main>
      </div>
    </Wrapper>
  );
};

const AdminOrders = ({ products }) => {
  const [users, setUsers] = useState({}); // State to store fetched user data
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [open, setOpen] = useState(false);

  const { pending } = useSelector((state) => state.user);

  useEffect(() => {
    // Fetch user data for each unique creatorID and store it in the users state
    const fetchUserDetails = async (creatorIDs) => {
      const userDetails = {};
      await Promise.all(
        creatorIDs.map(async (id) => {
          if (!userDetails[id]) {
            userDetails[id] = await getUserByID(id);
          }
        })
      );
      setUsers(userDetails);
    };

    const creatorIDs = Array.from(
      new Set(products.map((product) => {
        if(product.creatorID !== undefined) {
          return product.creatorID;
        }
        return null;
      }))
    );
    if(creatorIDs.length !== 0) {
      fetchUserDetails(creatorIDs);
    }
  }, [products]);

  const getUserByID = async (id) => {
    try {
      const response = await getSingleUser(id, dispatch);
      return response;
    } catch (error) {
      throw error; // Rethrow the error to be handled higher up the call stack
    }
  };

  const handleOpen = async (index) => {
    if (index && users[index] !== "eBaaba") {
      setHoveredIndex(index);
      setOpen(!open);
    }
  };

  return (
    <>
      <TableBody style={{ position: "relative" }}>
        {products.map((value, index) => {
          const isSoldbyeBaaba = users[value.creatorID] === "eBaaba";

          return (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <img
                  src={value.img}
                  style={{
                    width: "40px",
                    height: "40px",
                    verticalAlign: "middle",
                    objectFit: "cover",
                  }}
                />
              </TableCell>
              <TableCell>{value.productName}</TableCell>
              <TableCell>{value.qty}</TableCell>
              <TableCell>
                {!value.attribute ? "N/A" : JSON.stringify(value.attribute)}
              </TableCell>
              <TableCell>
                <span
                  onClick={() => handleOpen(value.creatorID)}
                  title={isSoldbyeBaaba ? "" : "Click to see information"}
                  style={{
                    cursor: isSoldbyeBaaba ? "default" : "pointer",
                  }}
                >
                  {users[value.creatorID] === "eBaaba" ? (
                    <>{users[value.creatorID]}</>
                  ) : (
                    <>{users[value.creatorID]}</>
                  )}
                </span>
              </TableCell>

              {/* Render user name */}
              <TableCell>
                {new Intl.NumberFormat().format(
                  Number(value.salePrice) * Number(value.qty)
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <UserInfoModal
        close={handleOpen}
        show={open}
        hoveredIndex={hoveredIndex}
      />
    </>
  );
};

const VendorOrders = ({ products }) => {
  return (
    <TableBody>
      {products.map((value, index) => {
        return (
          <TableRow key={index}>
            <TableCell component="th" scope="row">
              <img
                src={value.img}
                style={{
                  width: "40px",
                  height: "40px",
                  verticalAlign: "middle",
                  objectFit: "cover",
                }}
              />
            </TableCell>
            <TableCell>{value.productName}</TableCell>
            <TableCell>{value.qty}</TableCell>
            <TableCell>
              {!value.attribute ? "N/A" : JSON.stringify(value.attribute)}
            </TableCell>
            <TableCell>
              {new Intl.NumberFormat().format(
                Number(value.salePrice) * Number(value.qty)
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default EditOrder;
