import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllRegisteredVendors } from "../../services/VendorServices";
import { updateProductOwnership } from "../../services/ProductService";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: theme.spacing(2.5)
  },
  textField: {
    display: 'flex',
    width: '100%'
  }
}));


export default function UpdateOwnership({ show, close, checkedItems }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pending } = useSelector((state) => state.product);
  const [options, setOptions] = useState([{ label: "", value: "", id: 0, business_name: "" }]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [vendors, setVendors] = useState([]);

  const handleOptionSelected = (event, newValue) => {
    setSelectedOption(newValue);
  };


  useEffect(() => {
    fetchAllVendors();
    fetchProducts();
  }, [selectedOption, show]);


  const fetchAllVendors = async () => {
    try {
      const response = await getAllRegisteredVendors(dispatch);
      let vendors = [];
      response.data.data?.map((item) => {
        vendors.push(item);
      });
      setVendors([...vendors]);
    } catch (error) { }
  };

  const fetchProducts = () => {
    const temp = [];
    vendors?.map((vendor) => {
      temp.push({
        label: vendor?.name,
        value: vendor?.name,
        business_name: vendor?.business_name,
        id: vendor?.id,
      });
    });

    setOptions(temp);
  };


  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      if(selectedOption !== null) {
        updateProductOwnership(selectedOption?.id, checkedItems, dispatch);
        show();
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <div>
      <Dialog open={close} onClose={() => show()} className={classes.dialog}>
        <DialogTitle>Update Ownership</DialogTitle>
        <DialogContent >
          <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) =>
              option.value === value.value
            }
            value={selectedOption}
            onChange={handleOptionSelected}
            renderInput={(params) => (
              <TextField
                size="large"
                {...params}
                label="Select User"
                variant="outlined"
                className={classes.textField}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off",
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => show()}>Cancel</Button>
          <Button onClick={handleUpdate} variant="contained" color="primary">
            Update Ownership
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
