import React, { useEffect } from 'react';
import { Grid, Typography, Card, CardContent, Button, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading/Loading';

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(2),
        height: '100%'
    },

    cardInner: {
        height: '400px',
        overflowY: 'scroll'
    },
    emptyCart: {
        textAlign: 'center',
        padding: theme.spacing(2),
    },
    cardTitle: {
        display: 'flex',
        // width: '100%',
        justifyContent: 'space-between',
        borderBottom: '1px solid #929292',
        padding: '10px',
        fontSize: '20px'
    },
    cartItem: {
        marginBottom: theme.spacing(2),

    },
    cardContent: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px dotted #929292',

    },
    cartActions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    cardFooter: {
        padding: '20px 10px'
    },
    footerTitle: {
        borderTop: '1px solid #929292',
        padding: '10px auto',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));


const CartItems = ({ items, numberOfCartItems, cartTotal, loadingCart }) => {

    const classes = useStyles();


    return (
        <Card className={classes.card}>
            <Typography variant='h5' className={classes.cardTitle}><span>Order Summary</span><span> <span>
                {numberOfCartItems}{" "}
                {numberOfCartItems === 1 ? "Item" : "Items"}
            </span></span></Typography>

            <CardContent className={classes.cardInner}>
                {items?.length >= 1 || (loadingCart && Object.keys(items).length === 0) ? (

                    <Grid container spacing={2}>

                        {items.map((item, index) => (
                            <Grid item xs={12} key={index} className={classes.cartItem}>
                                <img
                                    // alt={name !== undefined ? name : null}
                                    src={JSON.parse(item.url)[0]}
                                    className={classes.cardImage}
                                    style={{
                                        width: "60px",
                                        height: "60px",

                                    }}
                                />
                                <div className={classes.cardContent}>
                                    <Typography variant="body1">{item.name}</Typography>
                                    <Typography variant="body2">{item.sale_price} x {item.qty}</Typography>
                                </div>

                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <div className={classes.emptyCart}>
                        <img
                            alt="Card Logo"
                            src='../../../icon.png'
                            style={{
                                width: "100%",
                                height: "auto",

                            }}
                        />
                        <Typography variant="h5">Your cart is empty</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            href="https://www.ebaaba.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ marginTop: 20 }}
                        >
                            Add Items
                        </Button>

                    </div>
                )}
            </CardContent>

            <div className={classes.cardFooter}>
                <Typography variant='h5' className={classes.footerTitle}><span>Order Total:</span><span>{`D${cartTotal.toLocaleString()}`}</span></Typography>
            </div>
        </Card>
    );
};

export default CartItems;
