import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link, useParams } from "react-router-dom";
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { sendTokenEmail } from "../../services/UserManagementService";
import { useDispatch, useSelector } from "react-redux";
import { CardHeader } from "@material-ui/core";
import "./reset-password.css";
import Loading from "../../components/Loading/Loading";

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: "visible",
  },
  session: {
    position: "relative",
    zIndex: 4000,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 auto",
    flexDirection: "column",
    minHeight: "100%",
    textAlign: "center",
  },
  wrapper: {
    flex: "none",
    maxWidth: "400px",
    width: "100%",
    margin: "0 auto",
  },
  fullWidth: {
    width: "100%",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
  },
}));

const PasswordReset = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const { tokenSuccess, pending } = useSelector((state) => state.user);

  const handleReset = async (e) => {
    e.preventDefault();
    try {
      sendTokenEmail(email, dispatch);
    } catch (error) {
      setShow(false);
    }
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <div className={classNames(classes.session, classes.background)}>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          {!tokenSuccess ? (
            <Card>
              <CardContent>
                <form>
                  <div
                    className={classNames(classes.logo, `text-xs-center pb-xs`)}
                  >
                    <img
                      src={`https://res.cloudinary.com/www-ebaaba-com/image/upload/v1682029596/ebaaba-logo_kzmjyx.png`}
                      style={{
                        width: "300px",
                        height: "140px",
                        verticalAlign: "middle",
                        objectFit: "cover",
                        margin: "auto",
                        filter:
                          "brightness(0) saturate(100%) invert(16%) sepia(100%) saturate(7478%) hue-rotate(207deg) brightness(95%) contrast(103%)",
                      }}
                      alt="eBaaba logo"
                      className="block"
                    />
                    <Typography variant="caption">
                      Enter your email and we'll send you instructions on how to
                      reset your password.
                    </Typography>
                  </div>
                  <TextField
                    id="email"
                    label="Email Address"
                    className={classes.textField}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="mt-1"
                    type="submit"
                    onClick={handleReset}
                  >
                    Send password reset
                  </Button>
                  <div className="pt-1 text-xs-center">
                    <Link to="/signin">
                      <Button>Sign</Button>
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to="/signup">
                      <Button>Create new account.</Button>
                    </Link>
                  </div>
                </form>
              </CardContent>
            </Card>
          ) : (
            <Card>
              <CardContent className="card-container">
                <h2 className="heading-title">Password Reset</h2>
                <p>
                  Check the email account associated with your username for
                  instructions resetting your password. Remember to look in your
                  spam folder, where automated messages sometimes filter. If you
                  still can't log in, contact eBaaba Customer Service.
                </p>
                <Button variant="contained" color="primary">
                  <a href="/signin">Return to login</a>
                </Button>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
