import http from "../http-common";
import { toast } from "react-toastify";
// import Swal from "sweetalert2";

import {
  handleDashboardRequest,
  createDashboardSuccess,
  getTotalSalesSuccess,
  getTotalOrderSuccess,
  getTotalProfitSuccess,
  getTotalWeeklySalesSuccess,
  getNewOrdersSuccess,
  getTotalWeeklyProfitSuccess,
  getWeeklyOrdersSuccess,
  getTotalDailyProfitSuccess,
  getWeeklySalesSuccess,
  handleDashboardError,
} from "../redux/Slices/DashboardSlice";

export const getTotalSales = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("total-sales")
    .then((response) => {
      dispatch(getTotalSalesSuccess(response.data.TotalSales));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getTotalVendorSales = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("total-vendor-sales")
    .then((response) => {
      dispatch(getTotalSalesSuccess(response.data.TotalSales));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getTotalOrders = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("all-orders")
    .then((response) => {
      dispatch(getTotalOrderSuccess(response.data.Orders));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getTotalVendorOrders = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("all-vendor-orders")
    .then((response) => {
      dispatch(getTotalOrderSuccess(response.data.Orders));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
}

export const getTotalProfit = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("total-profit")
    .then((response) => {
    
      dispatch(getTotalProfitSuccess(response.data.Profit));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getTotalVendorProfit = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("total-vendor-profit")
    .then((response) => {
      dispatch(getTotalProfitSuccess(response.data.Profit));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getTotalWeeklySales = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("total-weekly-sales")
    .then((response) => {
      dispatch(getTotalWeeklySalesSuccess(response.data.Weekly));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getVendorTotalWeeklySales = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("total-weekly-vendor-sales")
    .then((response) => {
      dispatch(getTotalWeeklySalesSuccess(response.data.Weekly));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getNewOrders = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("new-orders")
    .then((response) => {
      dispatch(getNewOrdersSuccess(response.data.NewOrders));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getVendorNewOrders = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("new-vendor-orders")
    .then((response) => {
      dispatch(getNewOrdersSuccess(response.data.NewOrders));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getTotalWeeklyProfit = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("total-weekly-profit")
    .then((response) => {
      dispatch(getTotalWeeklyProfitSuccess(response.data.TotalWeeklyProfit));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getVendorTotalWeeklyProfit = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("total-weekly-vendor-profit")
    .then((response) => {
      dispatch(getTotalWeeklyProfitSuccess(response.data.TotalWeeklyProfit));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};


export const getWeeklyOrders = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("weekly-orders")
    .then((response) => {
      dispatch(getWeeklyOrdersSuccess(response.data.WeeklyOrders));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getVendorWeeklyOrders = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("weekly-vendor-orders")
    .then((response) => {
      dispatch(getWeeklyOrdersSuccess(response.data.WeeklyOrders));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getTotalDailyProfit = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("total-daily-profit")
    .then((response) => {
      dispatch(getTotalDailyProfitSuccess(response.data.TotalDailyProfit));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getWeeklySales = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("weekly-sales")
    .then((response) => {
      dispatch(getWeeklySalesSuccess(response.data.WeeklySales));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};

export const getVendorWeeklySales = (dispatch) => {
  dispatch(handleDashboardRequest());
  http
    .get("weekly-vendor-sales")
    .then((response) => {
      dispatch(getWeeklySalesSuccess(response.data.WeeklySales));
    })
    .catch((error) => {
      dispatch(handleDashboardError());
    });
};
