import {
  CartCard,
  ImageStatCard,
  MapCard,
  NewsCard,
  PostCard,
  ProfileCard,
  StatCard,
  WeatherCard,
  Wrapper,
} from "../../components";
import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import EmailIcon from "@material-ui/icons/Email";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PhoneIcon from "@material-ui/icons/Phone";
import { mockFeed } from "../../utils/mock";
import red from "@material-ui/core/colors/red";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  Card,
  CardContent,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
import { getAllUsers } from "../../services/UserManagementService";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "../../services/UserManagementService";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
  paginationMenu: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 10px",
    listStyleType: "none",
  },
  menuItem: {
    margin: "0 15px",
    listStyleType: "none",
    display: "none",
  },
  card: {
    position: "relative",
    clear: "both",
  },
  appBar: {
    boxShadow: theme.shadows[0],
  },
}));

const ManageUsers = (props) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState({ page: 1 });
  const [pagination, setPagination] = useState({ last_page: 0 });

  const { pending } = useSelector((state) => state.user);

  useEffect(() => {
    getUsers();
  }, [page]);

  const getUsers = async () => {
    try {
      const response = await getAllUsers(dispatch, page.page);
      let users = [];
      response.data.data.data?.map((item) => {
        users.push(item);
      });
      setUsers([...users]);
      setPagination({ last_page: response.data.data.last_page });
    } catch (error) {}
  };

  const next = () => {
    let currentPage = Number(page.page) + 1;
    setPage({ page: currentPage });
    window.scrollTo(0, 0);
    props.history.push("/user/manage-users?page=" + currentPage);
  };

  const prev = () => {
    let currentPage = Number(page.page) - 1;
    if (currentPage === 0) {
      setPage({ page: 1 });
      window.scrollTo(0, 0);
      props.history.push("/user/manage-users?page=" + currentPage);
    } else {
      setPage({ page: currentPage });
      window.scrollTo(0, 0);
      props.history.push("/user/manage-users?page=" + currentPage);
    }
  };

  const renderPagination = () => {
    const currentPage = Number(page.page);
    const lastPage = Number(pagination.last_page);
    const pagesToShow = 1; // Number of pages to show before the ellipsis

    const paginationItems = [];

    // First page
    if (currentPage !== 1) {
      paginationItems.push(
        <Button
          key={1}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(1)}
          style={{ marginRight: "10px" }}
        >
          1
        </Button>
      );
    }

    // If current page is not too close to the beginning, add ellipsis
    if (currentPage > pagesToShow + 1) {
      paginationItems.push(<span key="ellipsis1">...</span>);
    }

    // Pages before current page
    for (let i = Math.max(2, currentPage - pagesToShow); i < currentPage; i++) {
      paginationItems.push(
        <Button
          key={i}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(i)}
          style={{ margin: "auto 10px" }}
        >
          {i}
        </Button>
      );
    }

    // Current page
    paginationItems.push(
      <Button
        key={currentPage}
        variant="contained"
        className="pageLink"
        disabled
        style={{ marginRight: "10px" }}
      >
        {currentPage}
      </Button>
    );

    // Pages after current page
    for (
      let i = currentPage + 1;
      i <= Math.min(currentPage + pagesToShow, lastPage - 1);
      i++
    ) {
      paginationItems.push(
        <Button
          key={i}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(i)}
          style={{ marginRight: "10px" }}
        >
          {i}
        </Button>
      );
    }

    // If current page is not too close to the end, add ellipsis
    if (lastPage - currentPage > pagesToShow) {
      paginationItems.push(<span key="ellipsis2">...</span>);
    }

    // Last page
    if (currentPage !== lastPage) {
      paginationItems.push(
        <Button
          key={lastPage}
          variant="outlined"
          className="pageLink"
          onClick={() => handlePageChange(lastPage)}
          style={{ margin: "auto 10px" }}
        >
          {lastPage}
        </Button>
      );
    }

    return paginationItems;
  };


  const handlePageChange = (pageNumber) => {
    setPage({ page: pageNumber });
    window.scrollTo(0, 0);
    props.history.push(`/user/manage-users?page=${pageNumber}`);
  };

  const handleDelete = async (email, user_role) => {
    console.log("email = ", email, " user role == ", user_role);
    try {
      deleteUser(email, user_role, props.token, dispatch);
    } catch (error) {}
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <Wrapper className={classes.root}>
      <Card className={classes.card}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography color="inherit" variant="h6" className="flexSpacer">
              Manage Users
            </Typography>
          </Toolbar>
        </AppBar>
        <CardContent className={classes.content}>
          <Paper
            style={{ width: "100%", marginTop: "20px", overflowX: "auto" }}
          >
            <Table style={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length >= 1 ? (
                  users?.map((value, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {value.name}
                        </TableCell>
                        <TableCell>{value.email}</TableCell>
                        <TableCell>{value.type}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: "5px", padding: "5px 0" }}
                          >
                            <EditIcon
                              style={{ width: "20px", height: "20px" }}
                            />
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ padding: "5px 0" }}
                            onClick={() =>
                              handleDelete(value.email, value.type)
                            }
                          >
                            <DeleteIcon
                              style={{ width: "20px", height: "20px" }}
                            />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell>No Users</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>

          {pagination.last_page >= 2 ? (
            <>
              <nav>
                <ul className="paginationMenu">
                  <li className={"menuItem"}>
                    <Button
                      variant="outlined"
                      disabled={Number(page.page) === 1}
                      className="pageLink"
                      onClick={prev}
                      style={{ marginRight: "10px" }}
                    >
                      Previous
                    </Button>
                  </li>
                  {renderPagination()}
                  <li className="menuItem">
                    <Button
                      variant="outlined"
                      className="pageLink"
                      disabled={
                        Number(page.page) === Number(pagination.last_page)
                      }
                      onClick={next}
                    >
                      Next
                    </Button>
                  </li>
                </ul>
              </nav>
            </>
          ) : null}
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default ManageUsers;
