import BookmarkIcon from "@material-ui/icons/Bookmark";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ShareIcon from "@material-ui/icons/Share";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { formatPrice } from "../../helpers";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mediaGrid: {
    textAlign: "center",
    overflow: "hidden",
  },
  mediaGridCompact: {
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  },
  figure: {
    position: "relative",
    cursor: "pointer",
    background: "rgba(0,0,0,.38)",
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    textAlign: "center",
    // Directly applying the hover styles
    transform: "translate3d(0,0,0)",
    opacity: 1,
    "& $caption::before": {
      transform: "translate3d(0,0,0)",
    },
  },
  // figure: {
  //   position: "relative",
  //   cursor: "pointer",
  //   background: "rgba(0,0,0,.38)",
  //   borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  //   textAlign: "center",
  //   "&:hover $captionAnchor": {
  //     transform: "translate3d(0,0,0)",
  //     opacity: 1,
  //   },
  //   "&:hover $caption::before": {
  //     transform: "translate3d(0,0,0)",
  //   },
  // },
  figureCompact: {
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  },
  figureImg: {
    position: "relative",
    display: "block",
    maxWidth: "100%",
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    transition: "opacity 300ms, transform 300ms",
    opacity: 0.85,
  },
  figureImgCompact: {
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  },
  badge: {
    color: theme.palette.primary.contrastText,
    textAlign: "center",
    position: "absolute",
    borderTop: `30px solid ${theme.palette.error.main}`,
    borderBottom: "30px solid transparent",
    borderRight: "30px solid transparent",
    borderLeft: `30px solid ${theme.palette.error.main}`,
    top: 0,
    left: 0,
    zIndex: 100,
  },
  badgeText: {
    position: "absolute",
    transform: "rotate(-45deg)",
    top: "-18px",
    left: "-25px",
    whiteSpace: "nowrap",
    color: theme.palette.primary.contrastText,
  },
  caption: {
    color: theme.palette.primary.contrastText,
    textTransform: "uppercase",
    backfaceVisibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    "&::before": {
      pointerEvents: "none",
      position: "absolute",
      width: "100%",
      height: "100%",
      borderStyle: "solid",
      borderColor: "rgba(0,0,0,.38)",
      content: "''",
      transition: "transform 300ms",
      right: 0,
      bottom: 0,
      borderWidth: "0 45px 0 0",
      transform: "translate3d(45px,0,0)",
    },
  },
  captionAnchor: {
    position: "relative",
    transition: "opacity 300ms, transform 300ms",
    // display: "block",
    color: theme.palette.primary.contrastText,
    // transform: "translate3d(90px,0,0)",
    margin: `${theme.spacing(1)}px 0`,
    "&:first-child": {
      transitionDelay: "0.025s",
    }
  },
  paragraph: {
    margin: 0,
    float: "right",
    clear: "both",
    textAlign: "center",
    textTransform: "none",
    fontSize: "1rem",
    width: "45px",
    position: "relative",
  },
  price: {
    display: "flex",
    alignItems: "baseline",
    width: "30%",
    marginRight: "10px",
  },
  mainPrice: {
    color: "green",
  },
  name: {
    width: "60%",
    overflow: "hidden",
  },
  productName: {
    display: "block",
    width: "99%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const ManageOwnershipCard = ({
  id,
  name,
  url,
  price,
  description,
  sale,
  regular,
  discounted,
  discount,
  slug,
  checked,
  click
}) => {

  const classes = useStyles();

  return (
    <Card>

      <div className={classes.mediaGrid}>
        <figure className={classes.figure}>
          <img
            alt={name !== undefined ? name : null}
            src={url !== undefined ? JSON.parse(url)[0] : null}
            className={classes.figureImg}
            style={{
              width: "100%",
              height: "400px",
              verticalAlign: "middle",
              objectFit: "cover",
            }}
          />
          <figcaption className={classes.caption}>
            <p className={classes.paragraph}>

              <FormGroup>
                <FormControlLabel
                  className={classes.captionAnchor}
                  control={<Checkbox checked={checked} onChange={click} />}
                />
              </FormGroup>

            </p>
          </figcaption>
        </figure>
      </div>
      <CardContent className="pa-1">
        <div>
          <Link >
            <Typography className={classes.productName}>
              {name !== undefined ? name : null}
            </Typography>
          </Link>
          <div
            className={classNames(classes.price, "mat-text-primary text-xl")}
          >
            {regular && (
              <Typography
                variant="caption"
                className="strikethrough"
                component="span"
              >
                {formatPrice(regular)}
              </Typography>
            )}
            <Typography variant="h6" className={classes.mainPrice}>
              {formatPrice(price)}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

ManageOwnershipCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
  description: PropTypes.string,
  sale: PropTypes.bool,
  discounted: PropTypes.bool,
  discount: PropTypes.number,
};

export default ManageOwnershipCard;
