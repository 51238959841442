import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState: {
    dashboards: [],
    totalSales: 0,
    totalOrders: 0,
    totalProfit: 0,
    totalWeeklySales: 0,
    newOrders: 0,
    totalWeeklyProfit: 0,
    weeklyOrders: [],
    totalDailyProfit: [],
    weeklySales: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleDashboardRequest: (state) => {
      state.pending = true;
    },
    createDashboardSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },
    getDashboardSuccess: (state, action) => {
      state.pending = false;
      state.dashboards = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getTotalSalesSuccess: (state, action) => {
      state.pending = false;
      state.totalSales = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getTotalOrderSuccess: (state, action) => {
      state.pending = false;
      state.totalOrders = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getTotalProfitSuccess: (state, action) => {
      state.pending = false;
      state.totalProfit = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getTotalWeeklySalesSuccess: (state, action) => {
      state.pending = false;
      state.totalWeeklySales = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getNewOrdersSuccess: (state, action) => {
      state.pending = false;
      state.newOrders = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getTotalWeeklyProfitSuccess: (state, action) => {
      state.pending = false;
      state.totalWeeklyProfit = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getWeeklyOrdersSuccess: (state, action) => {
      state.pending = false;
      state.weeklyOrders = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getTotalDailyProfitSuccess: (state, action) => {
      state.pending = false;
      state.totalDailyProfit = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getWeeklySalesSuccess: (state, action) => {
      state.pending = false;
      state.weeklySales = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleDashboardError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleDashboardRequest,
  createDashboardSuccess,
  getDashboardSuccess,
  getTotalSalesSuccess,
  getTotalOrderSuccess,
  getTotalProfitSuccess,
  getTotalWeeklySalesSuccess,
  getNewOrdersSuccess,
  getTotalWeeklyProfitSuccess,
  getWeeklyOrdersSuccess,
  getTotalDailyProfitSuccess,
  getWeeklySalesSuccess,
  handleDashboardError
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
