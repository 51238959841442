import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  getDeliveryCharge,
  updateDeliveryCharge,
} from "../../services/DeliveryChargeService";
import Loading from "components/Loading/Loading";

export default function EditDelivery({ show, close, deliveryChargeId, pending }) {
  const dispatch = useDispatch();
  const [deliveryCharge, setDeliveryCharge] = useState({
    key: '',
    value: ''
  });

  const getDeliveryChargeById = async () => {
    try {
      const response = await getDeliveryCharge(deliveryChargeId, dispatch);
      setDeliveryCharge({key: response[0]?.key, value: response[0]?.value});
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (deliveryChargeId !== undefined) {
      getDeliveryChargeById();
    }
  }, [deliveryChargeId]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      updateDeliveryCharge(deliveryCharge?.value, deliveryChargeId, dispatch);
      show();
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeliveryCharge((prevDelivery) => ({
      ...prevDelivery,
      [name]: value,
    }));
  };

  if(pending){
    return <Loading />
  }

  return (
    <Fragment>
      {deliveryChargeId !== undefined ? (
        <div>
          <Dialog open={close} onClose={() => show()}>
            <DialogTitle>Edit {deliveryCharge?.key}</DialogTitle>
            <form style={{ margin: "10px 20px" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Delivery Charge Value"
                    type="text"
                    name="value"
                    id="value"
                    value={deliveryCharge?.value}
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <DialogActions
                style={{
                  display: "flex",
                  padding: "10px 0",
                  justifyContent: "space-between",
                }}
              >
                <Button onClick={handleUpdate} variant="contained" color="primary">
                  Save
                </Button>
                <Button onClick={() => show()}>Cancel</Button>
              </DialogActions>
            </form>
          </Dialog>
        </div>
      ) : null}
    </Fragment>
  );
}
