import { ActivityStream, Wrapper } from "../../components";
import React, { Fragment, useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Swal from "sweetalert2";
import AddLocation from "./AddLocation";
import { toast } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  deleteCategory,
  deleteSubCategory,
} from "../../services/CategoryService";
import { getLocations, deleteLocation } from "../../services/LocationService";
import EditCategoryPrices from "./EditCategoryPrices";

const useStyles = makeStyles((theme) => ({
  avatar: {
    display: "inline-block",
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      width: 128,
      height: 128,
    },
    [theme.breakpoints.down("xs")]: {
      width: 64,
      height: 64,
    },
    marginBottom: theme.spacing(1),
  },
  tabRoot: {
    textTransform: "initial",
    color: theme.palette.text.primary,
  },
  postInput: {
    border: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1) * 3,
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1) * 2,
    },
    fontSize: "13px",
    outline: 0,
    backgroundColor: theme.palette.background.paper,
  },
  snackbar: {
    margin: theme.spacing(1),
  },
}));

const TabContainer = (props) => (
  <Typography component="div" className="pa-0">
    {props.children}
  </Typography>
);


// function to edit and delete sub category item
const ActionDistance = (props) => {
  return (
    <div>
      <Typography>{new Intl.NumberFormat().format(props.distance)}</Typography>
    </div>
  );
};

// function to edit and delete category item
const ActionLocation = ({ location_id, handleDelete, handleEdit }) => {
  return (
    <div>
      <Button
        onClick={() => handleEdit()}
        color="primary"
        style={{ color: "white" }}
        size="small"
      >
        <EditIcon />
      </Button>
      <Button
        onClick={() => handleDelete(location_id)}
        color="secondary"
        size="small"
      >
        <DeleteIcon />
      </Button>
    </div>
  );
};

const ManageLocations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { pending, created, deleted, locations } = useSelector(
    (state) => state.location
  );

  useEffect(() => {
    getLocations(dispatch);
  }, [created, deleted]);

  const [options, setOptions] = useState([{ label: "", value: "", id: "", distance: 0 }]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const handleOptionSelected = (event, newValue) => {
    setSelectedOption(newValue);
  };
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const [toggleCategory, setToggleCategory] = useState(false);
  const handleToggleCategory = (index) => {
    setToggleCategory(!toggleCategory);
    setCategoryId(index);
  };

  const handleDeleteLocation = (index) => {
    try {
      deleteLocation(index, dispatch);
    } catch (error) {}
  };


  // Fetch locations and saves it on the options state
  const fetchLocations = () => {
    const temp = [];
    locations?.map((location) => {
      temp.push({
        label: location?.location_name,
        value: location?.location_name,
        distance: location?.distance,
        id: location?.id,
      });
    });

    setOptions(temp);
  };

  useEffect(() => {
    fetchLocations();
  }, [selectedOption, pending]);

  const selectedLocation = locations?.filter(
    (location) => location?.id == selectedOption?.id
  );

  return (
    <Wrapper>
      <Grid container spacing={1} style={{ justifyContent: "center" }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card>
            <CardContent
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => handleToggle()}
                variant="contained"
                color="primary"
              >
                Add Location
              </Button>
              <Autocomplete
                style={{ width: "200px", height: "auto" }}
                options={options}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                value={selectedOption}
                onChange={handleOptionSelected}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="Select Location"
                    variant="outlined"
                    className={classes.textField}
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </CardContent>
            <Divider />
            <CardContent>
              {selectedOption !== null ? (
                <>
                  {selectedLocation?.map((location, index) => {
                    const { location_name, distance  } = location;
                    return (
                      <div key={index}>
                        <SnackbarContent
                          className={classes.snackbar}
                          style={{ backgroundColor: "#303F9F" }}
                          message={location_name}
                          action={
                            <ActionLocation
                              location_id={location?.id}
                              handleDelete={() =>
                                handleDeleteLocation(location?.id)
                              }
                              handleEdit={() =>
                                handleToggleCategory(location?.id)
                              }
                            />
                          }
                        />
                         <div key={index} style={{ marginLeft: "20px" }}>
                              <SnackbarContent
                                className={classes.snackbar}
                                message={"Distance in KM"}
                                action={<ActionDistance distance={distance} />}
                              />
                            </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <h3>No Location Selected</h3>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Add Location Modal */}
      <AddLocation show={handleToggle} close={toggle} />
      {/* Edit Location Modal */}
      <EditCategoryPrices
        show={handleToggleCategory}
        close={toggleCategory}
        categoryId={categoryId}
      />
 
    </Wrapper>
  );
};

export default ManageLocations;
