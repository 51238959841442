import { createSlice } from "@reduxjs/toolkit";

export const deliveryChargeSlice = createSlice({
  name: "Delivery Charges",
  initialState: {
    deliveryCharges: [],
    weightFactors: [],
    pending: false,
    created: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleDeliveryChargeRequest: (state) => {
      state.pending = true;
    },
    createDeliveryChargeSuccess: (state) => {
      state.pending = false;
      state.error = false;
      state.created = true;
      state.errorMsg = "";
    },
    getDeliveryChargeSuccess: (state, action) => {
      state.pending = false;
      state.created = false;
      state.deliveryCharges = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    getWeightFactorSuccess: (state, action) => {
      state.pending = false;
      state.created = false;
      state.weightFactors = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    handleDeliveryChargeError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.created = false;
      state.errorMsg = action.payload;
    },
  },
});

export const {
  handleDeliveryChargeRequest,
  createDeliveryChargeSuccess,
  getDeliveryChargeSuccess,
  getWeightFactorSuccess,
  handleDeliveryChargeError,
} = deliveryChargeSlice.actions;
export default deliveryChargeSlice.reducer;
