import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
import { useState } from "react";
import { toast } from "react-toastify";
import { addLocation } from "../../services/LocationService";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function AddLocation({ show, close }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pending } = useSelector((state) => state.location);
  const [locationName, setLocationName] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      addLocation(locationName, dispatch);
      show();
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setLocationName(e.target.value);
  };

  if (pending) {
    return <Loading />;
  }

  return (
    <div>
      <Dialog open={close} onClose={() => show()}>
        <DialogTitle>Add New Location</DialogTitle>
        <DialogContent style={{ width: "280px" }}>
          <TextField
            autoFocus
            margin="dense"
            label="Location Name"
            type="text"
            name="locationName"
            id="locationName"
            onChange={handleChange}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => show()}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Add Location
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
